import afterFetchList             from "@/objects/stateMergers/list/afterFetch";
import afterUpdateList            from "@/objects/stateMergers/list/afterUpdate";
import afterFetchColleague        from "@/objects/stateMergers/colleague/afterFetch";
import afterAllStudent            from "@/objects/stateMergers/student/afterAll";
import afterFetchMedia            from "@/objects/stateMergers/media/afterFetch";
import afterAllOrganizerContent   from "@/objects/stateMergers/organizercontent/afterAll";
import afterAllGroup              from "@/objects/stateMergers/group/afterAll";
import afterAllYeargroup          from "@/objects/stateMergers/yeargroup/afterAll";
import afterAllClass              from "@/objects/stateMergers/class/afterAll";
import afterAllCompetence         from "@/objects/stateMergers/competence/afterAll";
import beforeCreateCompetence     from "@/objects/stateMergers/competence/beforeCreate";
import afterAllCompetenceCategory from "@/objects/stateMergers/competenceCategory/afterAll";
import afterAllShare              from "@/objects/stateMergers/share/afterAll";

export default class StateMergers
{

    constructor( core )
    {

        this.mergers = {
            student           : {
                afterFetch : new afterAllStudent( core ),
                afterUpdate: new afterAllStudent( core )
            },
            class             : {
                afterFetch : new afterAllClass( core ),
                afterUpdate: new afterAllClass( core ),
                append     : {
                    afterUpdate: 'student'
                }
            },
            group             : {
                afterFetch : new afterAllGroup( core ),
                afterUpdate: new afterAllGroup( core )
            },
            yeargroup         : {
                afterFetch : new afterAllYeargroup( core ),
                afterUpdate: new afterAllYeargroup( core )
            },
            list              : {
                afterFetch         : new afterFetchList( core ),
                afterUpdate        : new afterUpdateList( core ),
                alwaysRunAfterFetch: true
            },
            colleague         : {
                afterFetch: new afterFetchColleague( core )
            },
            media             : {
                afterFetch: new afterFetchMedia( core )
            },
            organizercontent  : {
                afterFetch : new afterAllOrganizerContent( core ),
                afterUpdate: new afterAllOrganizerContent( core )
            },
            competence        : {
                beforeCreate: new beforeCreateCompetence( core ),
                afterFetch  : new afterAllCompetence( core ),
                afterUpdate : new afterAllCompetence( core ),
                resortCache : [ [ 'level', 'ascending' ] ]
            },
            share             : {
                afterFetch : new afterAllShare( core ),
                afterUpdate: new afterAllShare( core )
            },
            unshare           : {
                afterFetch : new afterAllShare( core ),
                afterUpdate: new afterAllShare( core )
            },
            competenceCategory: {
                afterFetch : new afterAllCompetenceCategory( core ),
                afterUpdate: new afterAllCompetenceCategory( core )
            }
        }

        this.collectors = {
            list: {
                combilist: true
            }
        }

    }

    get( objectType, triggerWhen )
    {
        if( undefined !== this.mergers[ objectType ]
            && undefined !== this.mergers[ objectType ][ triggerWhen ] )
        {
            return this.mergers[ objectType ][ triggerWhen ]
        }
        return false
    }

    isCollector( objectType, subType )
    {
        return ( this.collectors[ objectType ] && true === this.collectors[ objectType ][ subType ] )
    }

}