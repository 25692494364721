/*eslint-disable*/
import imagesHashes from '@/classes/Helpers/Printing/Modules/ImagesHashes'

function _fontReset( doc, defaultColor )
{
    doc.setFont( 'quicksand', 'normal' )
    doc.setFontSize( 10 )
    if( undefined !== defaultColor )
    {
        doc.setTextColor( defaultColor[ 0 ], defaultColor[ 1 ], defaultColor[ 2 ] )
    }
}

function _printHeadline( doc, docHeadline )
{

    doc.setFont( 'quicksand', 'bold' )
    doc.setFontSize( 14 )
    doc.setTextColor( docHeadline.headline.color[ 0 ], docHeadline.headline.color[ 1 ], docHeadline.headline.color[ 2 ] )
    doc.text( docHeadline.headline.x, docHeadline.headline.y, docHeadline.headline.text )

    _fontReset( doc )
    doc.text( docHeadline.timestamp.x, docHeadline.timestamp.y, ' | ' + docHeadline.timestamp.text )

    doc.setDrawColor( docHeadline.headline.color[ 0 ], docHeadline.headline.color[ 1 ], docHeadline.headline.color[ 2 ] )
    for( let l in docHeadline.lines )
    {
        let line = docHeadline.lines[ l ]
        doc.line( line.x, line.y, line.x2, line.y2 )
    }

    _fontReset( doc )
    return docHeadline.y

}

function _getSubheaderOffset( subdimensions, index )
{
    if( subdimensions instanceof Array
        && null !== subdimensions[ index ] )
    {
        return subdimensions[ index ].h + 1
    }
    return 0
}

function _printHeaders( doc, y, headers )
{

    if( undefined !== headers )
    {

        let setup = headers.headerSetup

        if( undefined !== setup.rect )
        {
            doc.setFillColor( setup.color[ 0 ], setup.color[ 1 ], setup.color[ 2 ] )
            doc.rect( setup.rect.x, setup.rect.y, setup.rect.x2, setup.rect.y2, setup.rect.type )
        }

        doc.setDrawColor( 220, 220, 220 )
        if( undefined !== setup.lines && 0 < setup.lines.length )
        {
            for( let l in setup.lines )
            {
                let line = setup.lines[ l ]

                if( !isNaN( line.x )
                    && !isNaN( line.y )
                    && !isNaN( line.x2 )
                    && !isNaN( line.y2 ) )
                {
                    doc.line( line.x, line.y, line.x2, line.y2 )
                }

            }
        }

        doc.setFont( 'quicksand', 'bold' )
        if( undefined !== setup.headerFields )
        {
            for( let f in setup.headerFields )
            {
                let field   = setup.headerFields[ f ],
                    yOffset = _getSubheaderOffset( setup.subDimensions, f )

                doc.text( field.x, ( field.y - yOffset ), field.text, field.angle )
            }
        }

        doc.setFont( 'quicksand', 'normal' )
        if( setup.subHeaders instanceof Array )
        {
            for( let s in setup.subHeaders )
            {
                let sub = setup.subHeaders[ s ]
                if( null !== sub )
                {
                    let text = sub.text
                    if( null !== text )
                    {
                        let dims = doc.getTextDimensions( text )
                        doc.line( sub.x, ( sub.y - dims.h ), ( sub.x + dims.w ), ( sub.y - dims.h ) )
                        doc.text( sub.x, sub.y, text, sub.angle )
                    }
                }
            }
        }

        _fontReset( doc )
        return headers.headerY

    }
    return y

}

function _printRow( doc, y, yOffset, row, pageSetup, rowNumber, defaultColor )
{

    let rect   = pageSetup.rows.setup.rects[ rowNumber ],
        lines  = pageSetup.rows.setup.lines[ rowNumber ],
        defs   = pageSetup.raw.rows.defs,
        images = pageSetup.raw.rows.images

    if( rect instanceof Array )
    {
        for( let r in rect )
        {
            if( undefined !== rect[ r ] )
            {
                if( rect[ r ].fillcolor instanceof Array )
                {
                    doc.setFillColor( rect[ r ].fillcolor[ 0 ], rect[ r ].fillcolor[ 1 ], rect[ r ].fillcolor[ 2 ] )
                }
                else
                {
                    doc.setFillColor( rect[ r ].fillcolor )
                }
                doc.rect( rect[ r ].rect.x, ( rect[ r ].rect.y - yOffset ), rect[ r ].rect.x2, rect[ r ].rect.y2, rect[ r ].rect.type )
                if( rect[ r ].label !== undefined )
                {
                    doc.setFontSize( 10 )
                    doc.text( rect[ r ].rect.x + 1, ( rect[ r ].rect.y + rect[ r ].label.dims.h ), rect[ r ].label.text )
                    _fontReset( doc )
                }
            }
        }
    }
    else
    {
        if( undefined !== rect )
        {
            if( rect.fillcolor instanceof Array )
            {
                doc.setFillColor( rect.fillcolor[ 0 ], rect.fillcolor[ 1 ], rect.fillcolor[ 2 ] )
            }
            else
            {
                doc.setFillColor( rect.fillcolor )
            }
            doc.rect( rect.rect.x, ( rect.rect.y - yOffset ), rect.rect.x2, rect.rect.y2, rect.rect.type )
        }
    }

    if( undefined !== lines )
    {
        doc.setDrawColor( 220, 220, 220 )
        for( let l in lines )
        {
            let line = lines[ l ]
            doc.line( line.x, ( line.y - yOffset ), line.x2, ( line.y2 - yOffset ) )
        }
    }

    if( undefined !== row && 0 < row.length )
    {
        for( let r in row )
        {
            _fontReset( doc, defaultColor )
            if( undefined !== defs[ rowNumber ][ r ] )
            {
                switch( defs[ rowNumber ][ r ].type )
                {
                    case 'text':
                        if( 0 === parseInt( r ) )
                        {
                            doc.setFont( 'quicksand', 'bold' )
                        }
                        else
                        {
                            if( true === defs[ rowNumber ][ r ].pre )
                            {
                                doc.setFont( 'Courier', 'normal', 'bold' )
                                doc.setFontSize( 12 )
                            }
                            else
                            {
                                doc.setFont( 'quicksand', 'normal' )
                            }
                        }
                        if( undefined !== defs[ rowNumber ][ r ].color )
                        {
                            doc.setTextColor( defs[ rowNumber ][ r ].color[ 0 ], defs[ rowNumber ][ r ].color[ 1 ], defs[ rowNumber ][ r ].color[ 2 ] )
                        }
                        if( undefined !== defs[ rowNumber ][ r ].style )
                        {
                            doc.setFont( 'quicksand', defs[ rowNumber ][ r ].style )
                        }
                        if( undefined !== row[ r ].style )
                        {
                            doc.setFont( 'quicksand', row[ r ].style )
                        }
                        doc.text( row[ r ].x, ( parseFloat( '' + row[ r ].y ) - yOffset ), row[ r ].text )
                        break
                    case 'image':
                        for( let i in images[ rowNumber ][ r ] )
                        {

                            let image = images[ rowNumber ][ r ][ i ]
                            let imgX = row[ r ].x + ( parseInt( i ) * image.size )
                            let imgY = ( row[ r ].y - yOffset ) - image.size + image.padding
                            let opacityChange = ( -1 < image.img.indexOf( 'inactive' ) )
                            if( opacityChange )
                            {
                                doc.saveGraphicsState()
                                doc.setGState( new doc.GState( { opacity: 0.3 } ) )
                            }
                            if( image.src === true )
                            {
                                imgY += ( image.size - ( 1.5 * image.padding ) )
                                doc.addImage( image.img, 'png', imgX, imgY, image.size, image.size, image.img, 'FAST' )
                            }
                            else
                            {
                                doc.addImage( imagesHashes[ image.img ], 'png', imgX, imgY, image.size, image.size, image.img, 'FAST' )
                            }
                            if( opacityChange )
                            {
                                doc.restoreGraphicsState()
                            }
                        }
                        break
                    default:
                        break
                }
            }
        }
    }

    _fontReset( doc )

}

function _printFooter( doc, footer, page, pageNumber, punchMarks, landscape )
{

    let color  = footer.color,
        pmAddX = ( punchMarks && !landscape ? 10 : 0 ),
        pmAddY = ( punchMarks && landscape ? 10 : 0 )


    doc.setDrawColor( color[ 0 ], color[ 1 ], color[ 2 ] )

    for( let l in footer.lines )
    {
        let line = footer.lines[ l ]
        doc.line( ( line.x + pmAddX ), ( line.y + pmAddY ), ( line.x2 + pmAddX ), ( line.y2 + pmAddY ) )
    }

    for( let t in footer.texts )
    {
        let text = footer.texts[ t ]
        let textOut = '' + text.text
        if( '~~' === textOut )
        {
            textOut = '' + page
        }
        if( '__' === textOut )
        {
            textOut = '' + pageNumber
        }
        doc.setFont( 'quicksand', text.style )
        doc.setFontSize( text.size )
        if( undefined === text.link )
        {
            doc.text( ( text.x + pmAddX ), ( text.y + pmAddY ), textOut, { align: text.align } )
        }
        else
        {
            doc.textWithLink( textOut, ( text.x + pmAddX ), ( text.y + pmAddY ), {
                align: text.align,
                url  : text.link
            } )
        }

    }

    _fontReset( doc )

}

function _printPunchMarks( doc, landscape )
{

    let punchMarkPosition = 148.5
    if( landscape )
    {
        doc.line( punchMarkPosition, 0, punchMarkPosition, 8 )
    }
    else
    {
        doc.line( 0, punchMarkPosition, 8, punchMarkPosition )
    }

}

function printPages( doc, maxHeight, template, docHeadline, pageSetup, docFooter, filename, settings, landscape )
{

    let pageBreak      = true,
        pageNumber     = 1,
        rowNumber      = 0,
        yOffset        = 0,
        headY          = false,
        lastLineHeight = 0,
        punchMarks     = settings.printingWithPunchMarks === true,
        pmAddY         = ( punchMarks && landscape ? 10 : 0 ),
        y              = 0 + pmAddY

    if( null !== pageSetup )
    {

        while( pageSetup.rows.setup.cells.length > 0 )
        {

            if( pageBreak )
            {

                y = 0 + pmAddY

                if( 1 < pageNumber )
                {
                    doc.addPage()
                }
                y = _printHeadline( doc, docHeadline )
                y = _printHeaders( doc, y, pageSetup.headers )

                headY = ( false === headY ? y : headY )

            }

            let row = pageSetup.rows.setup.cells.shift()

            _printRow( doc, y, yOffset, row, pageSetup, rowNumber, docHeadline.headline.color )
            y += pageSetup.rows.setup.rowHeights[ rowNumber ]

            let nextRow = rowNumber + 1
            pageBreak = false
            lastLineHeight = pageSetup.rows.setup.rowHeights[ rowNumber ]

            if( undefined !== pageSetup.rows.setup.rowHeights[ nextRow ]
                && ( y + pageSetup.rows.setup.rowHeights[ nextRow ] > maxHeight )
                || undefined === pageSetup.rows.setup.rowHeights[ nextRow ] )
            {
                pageBreak = true
            }

            if( pageBreak )
            {
                yOffset += ( y - headY )
                pageNumber++
            }

            rowNumber++

        }

        if( null !== pageSetup.scores
            && undefined !== pageSetup.scores
            && undefined !== pageSetup.scores.scores.elements
            && pageSetup.scores.scores.elements instanceof Array )
        {

            let scores = pageSetup.scores.scores
            let height = pageSetup.scores.height

            y += 10

            if( ( y + height ) > maxHeight )
            {
                doc.addPage()
                pageNumber++
                y = _printHeadline( doc, docHeadline )
            }

            for( let e in scores.elements )
            {
                let elm = scores.elements[ e ]
                switch( elm.type )
                {
                    case 'rect':
                        doc.setFillColor( elm.color )
                        doc.rect( elm.x1, ( y + elm.y1 ), elm.x2, elm.y2, 'F' )
                        break
                    case 'text':
                        doc.setFontSize( elm.size )
                        doc.setFont( 'quicksand', elm.style )
                        doc.text( elm.text, elm.x, ( y + elm.y + elm.height ) )
                        if( elm.break )
                        {
                            y += elm.height + 1
                        }
                        _fontReset( doc )
                        break
                    case 'line':
                        doc.line( elm.x1, ( y + elm.y1 ), elm.x2, ( y + elm.y2 ) )
                        if( elm.break )
                        {
                            y += elm.height + 1
                        }
                        break
                }
            }

        }


        for( let i = 0; i < ( pageNumber - 1 ); i++ )
        {
            let p = i + 1
            doc.setPage( p )
            if( punchMarks )
            {
                _printPunchMarks( doc, landscape )
            }
            _printFooter( doc, docFooter, ( pageNumber - 1 ), p, punchMarks, landscape )
        }

        return true

    }

    return null

}

export default printPages