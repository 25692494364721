import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class CompetenceCategoriesTemplateObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !CompetenceCategoriesTemplateObjectClass.instance )
        {

            let refList                      = [
                    'templateId',
                    'title',
                    'color',
                    'labels',
                    'description',
                    'subCompetences',
                    'timestamp',
                    'update'
                ],
                personalAttributesStatesList = [
                    {
                        storage: 'pinning',
                        state  : 'pinned',
                        list   : 'pinLists'
                    },
                    {
                        storage: 'hiding',
                        state  : 'hidden',
                        list   : 'hideLists'
                    }
                ]

            super( core, 'competenceCategoryTemplate', refList, personalAttributesStatesList )
            this.isStudentShareable = false
            this.objectType = 'competenceCategoriesTemplates'
            this.creationDependsOn = [ 'competence' ]
            this.hasDetailView = true

            CompetenceCategoriesTemplateObjectClass.instance = this

        }

        return CompetenceCategoriesTemplateObjectClass.instance

    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return [ 'useTemplate', 'pin', 'hide' ]
    }

    getFormFields()
    {
        return [
            {
                ref        : 'templateId',
                caption    : 'TemplateId',
                type       : 'Hidden',
                reformatter: null,
                optional   : false,
                validator  : 'text',
                styleUpdate: false
            },
            {
                ref        : 'title',
                caption    : 'Bezeichnung',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'text',
                styleUpdate: false,
                placeholder: '_t'
            },
            {
                ref        : 'color',
                caption    : 'color',
                translate  : true,
                type       : 'ColorSelector',
                reformatter: null,
                optional   : false,
                validator  : 'color',
                styleUpdate: true
            },
            {
                ref        : 'labels',
                caption    : 'Labels',
                type       : 'LabelSelector',
                styleUpdate: false,
                reformatter: null,
                optional   : true,
                extended   : true,
                validator  : 'text'
            },
            {
                ref        : 'description',
                caption    : 'Beschreibung',
                type       : 'TextArea',
                reformatter: null,
                optional   : false,
                validator  : 'text',
                styleUpdate: false,
                placeholder: '_t'
            },
            {
                ref         : 'subCompetences',
                caption     : 'Teilkompetenzen',
                type        : 'SubCompetence',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false
            },

        ]
    }


}