<template>
    <div :class="'note hoverable '+item.color+( selected ? ' selected-item' : '' )"
         :id="'printable-'+item.localId"
         :key="'note-'+item.localId+'-'+$core.getBaseClassHelper().getElementKey( 'note', undefined, item.localId )"
         @click="handleClick()">
        <div class="header">
            <div class="element-information">
                <div class="base-meta">
                    <span :key="headerKey+'-timestamp'"
                          v-html="$core.getFriendlyTimestamp().friendlyTimestamp( item.timestamp )"></span>
                    <template v-if="0 < item.update"><br/>zuletzt bearbeitet
                        <span v-if="0 < item.update"
                              :key="headerKey+'-updated'"
                              v-html="$core.getFriendlyTimestamp().friendlyTimestamp( item.update )"></span>
                        <LastEditor :reference="item"
                                    :key="item.localId+'-lastEditor'"
                                    :elementKey="item.elementKey"/>
                    </template>
                </div>
                <div class="markers">
                    <ReferenceMarkers :item="item"/>
                </div>
            </div>
        </div>
        <div
            :class="'note-body sensitive noteBody'+( $core.settings().getSetting( 'supportMode' ) === true ? ' blurred' : '' )">
            <p :class="classList" v-html="$core.f().toHtml( item.body )"></p>
        </div>
    </div>
</template>

<script>
import ViewItemHandler       from '@/components/elements/viewItems/viewHandler/ViewItemHandler'
import LastEditor            from '@/components/elements/markers/LastEditor'
import ReferenceMarkers      from '@/components/elements/markers/ReferenceMarkers'
import MixinWatchElementKey  from "@/mixins/MixinWatchElementKey";
import MixinLivingTimestamps from "@/mixins/MixinLivingTimestamps";
import MixinComponentCleanup from "@/mixins/MixinComponentCleanup";
/*eslint-disable*/
export default {

    name      : 'Note',
    components: { ReferenceMarkers, LastEditor },
    extends   : ViewItemHandler,
    mixins    : [ MixinWatchElementKey, MixinLivingTimestamps, MixinComponentCleanup ],

    props: {
        item     : { type: Object, required: true },
        changeKey: { type: String, required: false },
        viewKey  : { type: String, required: false },
        selected : { type: Boolean, required: false, default: false }
    },

    emits: [ 'detailView', 'clicked' ],

    data()
    {
        return {
            unblurred: false
        }
    },

    computed: {
        classList()
        {
            return ( !this.unblurred && this.$props.item.blurry ) ? 'blurred' : ''
        }
    },

    methods: {

        handleClick()
        {

            this.$emit( 'clicked' )

            if( this.$props.item.blurry )
            {
                this.unblurred = !this.unblurred
            }

        }

    }

}
</script>