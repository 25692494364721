const dependencies = {
    addSelf           : false,
    addSubs           : 'lists',
    lockBaseIdentifier: true,
    lockBaseBy        : {
        location : 'columns',
        sub      : 'lists',
        subIndex : 0,
        index    : 0,
        attribute: 'filterBy',
        key      : 'filter'
    },
    required          : {
        class     : {
            resolver: [
                {
                    self     : true,
                    reference: 'localId',
                    location : 'columns',
                    sub      : 'lists',
                    subIndex : 0,
                    index    : 0,
                    type     : 'list',
                    attribute: 'filterBy',
                    filter   : {
                        key  : 'filter',
                        value: 'class'
                    }
                },
                {
                    self     : true,
                    deep     : true,
                    lookup   : 'competence',
                    reference: 'localId',
                    location : 'columns',
                    sub      : 'lists',
                    subIndex : -1,
                    index    : 0,
                    type     : 'list',
                    attribute: 'type',
                    filter   : {
                        key   : 'competenceSelector',
                        value : [ 'competence', '--CLN--' ],
                        helper: 'CompetenceCategoryTree',
                        call  : 'getAllLinkedCompetencesById'
                    }
                }
            ]
        },
        group     : {
            resolver: [
                {
                    self     : true,
                    reference: 'localId',
                    location : 'columns',
                    sub      : 'lists',
                    subIndex : 0,
                    index    : 0,
                    type     : 'list',
                    attribute: 'filterBy',
                    filter   : {
                        key  : 'filter',
                        value: 'group'
                    }
                },
                {
                    self     : true,
                    deep     : true,
                    lookup   : 'competence',
                    reference: 'localId',
                    location : 'columns',
                    sub      : 'lists',
                    subIndex : -1,
                    index    : 0,
                    type     : 'list',
                    attribute: 'type',
                    filter   : {
                        key   : 'competenceSelector',
                        value : [ 'competence', '--CLN--' ],
                        helper: 'CompetenceCategoryTree',
                        call  : 'getAllLinkedCompetencesById'
                    }
                }
            ]
        },
        yeargroup : {
            resolver: [
                {
                    self     : true,
                    reference: 'localId',
                    location : 'columns',
                    sub      : 'lists',
                    subIndex : 0,
                    index    : 0,
                    type     : 'list',
                    attribute: 'filterBy',
                    filter   : {
                        key  : 'filter',
                        value: 'yeargroup'
                    }
                },
                {
                    self     : true,
                    deep     : true,
                    lookup   : 'competence',
                    reference: 'localId',
                    location : 'columns',
                    sub      : 'lists',
                    subIndex : -1,
                    index    : 0,
                    type     : 'list',
                    attribute: 'type',
                    filter   : {
                        key   : 'competenceSelector',
                        value : [ 'competence', '--CLN--' ],
                        helper: 'CompetenceCategoryTree',
                        call  : 'getAllLinkedCompetencesById'
                    }
                }
            ]
        },
        competence: {
            resolver: [
                {
                    self     : true,
                    reference: 'localId',
                    location : 'columns',
                    sub      : 'lists',
                    subIndex : -1,
                    index    : 0,
                    type     : 'list',
                    attribute: 'type',
                    filter   : {
                        key   : 'competenceSelector',
                        value : [ 'competence', '--CLN--' ],
                        helper: 'CompetenceCategoryTree',
                        call  : 'getAllLinkedCompetencesById'
                    }
                }
            ]
        },
        student   : {
            resolver: [
                {
                    source   : 'class',
                    reference: 'localId',
                    location : 'columns',
                    sub      : 'lists',
                    subIndex : 0,
                    index    : 0,
                    type     : 'list',
                    attribute: 'filterBy',
                    filter   : {
                        key  : 'filter',
                        value: 'class'
                    }
                },
                {
                    source   : 'group',
                    reference: 'localId',
                    location : 'columns',
                    sub      : 'lists',
                    subIndex : 0,
                    index    : 0,
                    type     : 'list',
                    attribute: 'filterBy',
                    filter   : {
                        key  : 'filter',
                        value: 'group'
                    }
                },
                {
                    source   : 'yeargroup',
                    reference: 'localId',
                    location : 'columns',
                    sub      : 'lists',
                    subIndex : 0,
                    index    : 0,
                    type     : 'list',
                    attribute: 'filterBy',
                    filter   : {
                        key  : 'filter',
                        value: 'yeargroup'
                    }
                }
            ]
        }
    }
}
export default dependencies