<script>
/*eslint-disable*/
export default {

    name: "MixinLiveCollab",

    data()
    {
        return {
            live              : false,
            channel           : false,
            lastTick          : 0,
            othersActive      : false,
            foreignHighlighted: [],
            usersActive       : [],
            userDetails       : {},
            focussed          : [],
            focussedBy        : {}
        }
    },

    created()
    {

        if( false === this.$core.settings().getSetting( 'liveCollabMode' ) )
        {
            return
        }

        this.setupEvents()

    },

    mounted()
    {
        if( false === this.$core.settings().getSetting( 'liveCollabMode' ) )
        {
            return
        }
        this.__enterChannel()
    },

    beforeUnmount()
    {
        this.tearDown()
    },

    methods: {

        setupEvents()
        {

            this.live = this.$store.getters.online === true && this.$store.getters.authorized === true
            this.$core.getCoreTimer()
                .addInterval( 'live-collab-statecheck', 500, () =>
                {
                    this.__checkState()
                }, true, true )

            this.$core.getEventManager()
                .add( 'collab-channel-stats', ( data ) =>
                {
                    this.__processChannelStats( data )
                } )

            this.$core.getEventManager()
                .add( 'collab-channel-data', ( data ) =>
                {
                    this.__processChannelData( data )
                } )

        },

        tearDown()
        {
            this.$core.getEventManager()
                .remove( 'collab-channel-stats' )
            this.$core.getEventManager()
                .remove( 'collab-channel-data' )
            this.$core.getCoreTimer()
                .removeInterval( 'live-collab-statecheck' )
            this.__leaveChannel()
        },

        __checkState()
        {
            this.live = this.$store.getters.online === true && this.$store.getters.authorized === true
            if( Date.now() > this.lastTick + 2000 )
            {
                this.__channelTick()
            }
        },

        __enterChannel( force )
        {

            this.lastTick = Date.now()
            if( this.live && ( force || !this.channel ) )
            {
                this.$core.getClient()
                    .request( {
                        method  : 'live.enterChannel',
                        plain   : false,
                        id_local: this.$core.getCryptoHelper()
                                      .encryptForServer( this.$props.list.localId )
                    } )
                    .then( response =>
                    {
                        if( undefined !== response.channel
                            && undefined !== response.channel.id_channel )
                        {
                            this.channel = response.channel
                        }
                        else
                        {
                            setTimeout( () =>
                            {
                                this.__enterChannel()
                            }, 500 )
                        }
                    } )
            }
            else if( !this.live )
            {
                setTimeout( () =>
                {
                    this.__enterChannel()
                }, 500 )
            }

        },

        __channelTick()
        {
            this.lastTick = Date.now()
            this.$core.getClient()
                .request( {
                    method  : 'live.tick',
                    plain   : false,
                    id_local: this.$core.getCryptoHelper()
                                  .encryptForServer( this.$props.list.localId )
                } )
        },

        __leaveChannel()
        {
            this.$core.getClient()
                .request( {
                    method  : 'live.leaveChannel',
                    plain   : false,
                    id_local: this.$core.getCryptoHelper()
                                  .encryptForServer( this.$props.list.localId )
                } )
        },

        __processActiveUsers( data )
        {

            let localActive = [],
                drops       = []

            for( let u in data.users )
            {
                let user = data.users[ u ]

                if( -1 === localActive.indexOf( user.id ) )
                {
                    localActive.push( user.id )
                }
                if( -1 === this.usersActive.indexOf( user.id ) )
                {
                    this.usersActive.push( user.id )
                }
            }
            for( let u in this.usersActive )
            {
                if( -1 === localActive.indexOf( this.usersActive[ u ] ) )
                {
                    drops.push( this.usersActive[ u ] )
                }
            }
            for( let d in drops )
            {
                this.$core.f().removeFromArray( this.usersActive, drops[ d ] )
            }

        },

        __processFocus( data )
        {

            let localFocus = [],
                focussedBy = {},
                drops      = []

            for( let u in data.users )
            {
                let user = data.users[ u ]

                if( undefined !== user.focus
                    && user.id !== this.$store.getters.idUser )
                {
                    let focus = user.focus.value
                    if( -1 === localFocus.indexOf( focus ) )
                    {
                        localFocus.push( focus )
                        focussedBy[ focus ] = user.id
                    }
                    if( -1 === this.foreignHighlighted.indexOf( focus ) )
                    {
                        this.foreignHighlighted.push( focus )
                    }
                }

            }
            for( let f in this.foreignHighlighted )
            {
                if( -1 === localFocus.indexOf( this.foreignHighlighted[ f ] ) )
                {
                    drops.push( this.foreignHighlighted[ f ] )
                }
            }
            for( let d in drops )
            {
                this.$core.f().removeFromArray( this.foreignHighlighted, drops[ d ] )
            }
            this.focussedBy = focussedBy

        },

        __processNotifications()
        {

            let htmlList = []

            for( let u in this.usersActive )
            {
                if( this.usersActive[ u ] !== this.$store.getters.idUser )
                {

                    let colleague = this.$core.cc().getById( this.usersActive[ u ] )
                    htmlList.push( '<strong>' + colleague.displayName + '</strong>' )
                }
            }

            if( 0 < htmlList.length )
            {
                this.notificationCount = htmlList.length
                this.notificationActive = true
                this.notificationText = 'Element ist zur Zeit auch geöffnet von: ' + htmlList.join( ', ' )
            }
            else
            {
                this.notificationCount = 0
                this.notificationActive = false
                this.notificationText = ''
            }

        },

        __processChannelStats( stats )
        {

            this.$core.getCryptoHelper()
                .plainDecrypt( stats )
                .then( decryptedData =>
                {

                    try
                    {

                        let data = JSON.parse( decryptedData )
                        this.lastTick = Date.now()

                        let usercount = Object.keys( data.users ).length

                        if( undefined !== data.users
                            && ( 1 < usercount || this.othersActive ) )
                        {

                            this.othersActive = 1 < usercount

                            this.__processActiveUsers( data )
                            this.__processFocus( data )
                            this.__processNotifications()

                        }

                    }
                    catch( e )
                    {
                    }

                } )

        },

        __processChannelData( channeldata )
        {

            this.$core.getCryptoHelper()
                .plainDecrypt( channeldata )
                .then( decryptedData =>
                {

                    try
                    {

                        let data = JSON.parse( decryptedData )
                        this.lastTick = Date.now()
                        this.handleFieldUpdateCollab( data.id, data.type, data.method, data.elmId, data.value, data.id_author )

                    }
                    catch( e )
                    {
                    }

                } )

        },

        handleFocus( id )
        {

            if( this.live
                && 0 < this.usersActive.length )
            {

                this.$core.getClient()
                    .request( {
                        method  : 'live.focus',
                        id_local: this.$core.getCryptoHelper()
                                      .encryptForServer( this.$props.list.localId ),
                        value   : this.$core.getCryptoHelper()
                                      .encryptForServer( id )
                    } )
            }
        },

        handleBlur( id )
        {
            if( this.live
                && 0 < this.usersActive.length )
            {

                this.$core.getClient()
                    .request( {
                        method  : 'live.blur',
                        id_local: this.$core.getCryptoHelper()
                                      .encryptForServer( this.$props.list.localId ),
                        value   : this.$core.getCryptoHelper()
                                      .encryptForServer( id )
                    } )
            }
        },

        handleCollabData( id, type, method, elmId, value )
        {
            if( this.live
                && 0 < this.usersActive.length )
            {

                this.$core.getClient()
                    .request( {
                        method  : 'live.data',
                        id_local: this.$core.getCryptoHelper()
                                      .encryptForServer( this.$props.list.localId ),
                        data    : this.$core.getCryptoHelper()
                                      .encryptForServer( JSON.stringify( {
                                          id    : id,
                                          type  : type,
                                          method: method,
                                          elmId : elmId,
                                          value : value
                                      } ) )
                    } )
            }
        }

    }
}
</script>