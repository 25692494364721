<script>
import Button                from '@/components/elements/defaults/Button'
import PlannerElements       from '@/components/elements/viewItems/plannerView/PlannerElements'
import PlannerDay            from '@/components/elements/viewItems/plannerView/PlannerDay'
import MixinTypeTranslations from "@/mixins/MixinTypeTranslations";
import MixinCachePreheater   from "@/mixins/MixinCachePreheater";

export default {
    name      : 'PlannerViewFunctions',
    components: { PlannerDay, PlannerElements, Button },
    mixins    : [ MixinTypeTranslations, MixinCachePreheater ],

    created()
    {

        if( undefined !== this.$core.getState( 'detailViewFor' )
            && false !== this.$core.getState( 'detailViewFor' ) )
        {
            let temp = this.$core.getState( 'detailViewFor' ).split( ':' )
            this.filterBy = temp[ 0 ]
            this.filterId = temp[ 1 ]
        }

        this.setViewMode()
        this.awaitNeededCaches()
            .then( () =>
            {
                this.setup()
            } )

        this.hookKeys()

    },

    data()
    {
        return {
            lastDragChange: 0,
            datesDefined  : [],
            neededCaches  : [ 'note', 'todo', 'date', 'list', 'student' ]
        }
    },

    beforeUnmount()
    {
        this.hookKeys( false )
    },

    computed: {
        title()
        {
            if( !this.prepared )
            {
                return '...'
            }
            let title = ''
            switch( this.mode )
            {
                case 'month':
                    title = '<strong>' + this.$core.getFriendlyTimestamp().friendlyMonth( this.date.getMonth() ) + '</strong> ' + this.year
                    break
                case 'week':
                    title = '<strong>Woche ' +
                            this.$core.getFriendlyTimestamp().getCalendarWeek( new Date( this.getMonday( this.date ) ) ) + '</strong>: ' +
                            this.rangeFrom + ' - ' + this.rangeUntil
                    break
            }
            return title
        },
        allCacheKey()
        {
            let key = []
            for( let n in this.neededCaches )
            {
                key.push( this.$core.getBaseClassHelper().get( this.neededCaches[ n ] ).registry.cacheKey )
            }
            return key.join( '-' )
        },
        organizerTitle()
        {
            if( !this.prepared )
            {
                return '...'
            }
            return '<strong>Woche ' +
                   this.$core.getFriendlyTimestamp().getCalendarWeek( new Date( this.getMonday( this.organizerDate ) ) ) + '</strong>: ' +
                   this.organizerRangeFrom + ' - ' + this.organizerRangeUntil
        }
    },

    watch: {
        parentChangeKey: {
            immediate: true,
            handler( oldKey, newKey )
            {
                if( true !== this.$core.getState( 'listEditorOpen' )
                    && oldKey !== newKey
                    && this.mounted )
                {
                    this.changeKey = this.$core.getUuid().generate()
                }
            }
        },
        refreshKey     : {
            immediate: true,
            handler( oldKey, newKey )
            {
                if( true !== this.$core.getState( 'listEditorOpen' )
                    && oldKey !== newKey
                    && this.mounted )
                {
                    this.refresh()
                }
            }
        },
        selectedMode   : {
            immediate: true,
            handler( oldMode, newMode )
            {
                if( oldMode !== newMode
                    && this.mounted )
                {
                    this.setViewMode()
                    this.prepare()
                    this.refresh()
                }
            }
        },
        allCacheKey    : {
            immediate: true,
            handler( oldMode, newMode )
            {
                if( true !== this.$core.getState( 'listEditorOpen' )
                    && oldMode !== newMode
                    && this.mounted )
                {
                    this.prepare( this.date )
                    this.refresh()
                }
            }
        },
        selectMode     : {
            immediate: true,
            handler( newValue )
            {
                if( newValue === false )
                {
                    this.targetDay = false
                }
            }
        }
    },

    methods: {

        dayIndex( index )
        {
            let calc = index + 1
            while( calc > 7 )
            {
                calc -= 7
            }
            return calc
        },

        isWeekend( date )
        {
            return ( date.dateObject.getDay() > 5 || date.dateObject.getDay() === 0 )
        },

        isFiller( date )
        {
            return date.timestamp < this.realStart || date.timestamp > this.realEnd
        },

        fillerType( date )
        {
            return ' planner-filler'
                   + ( date.timestamp < this.realStart ? ' fill-before' : '' )
                   + ( date.timestamp > this.realEnd ? ' fill-after' : '' )
                   + ' fill-' + date.dateObject.getDay()
        },

        isToday( date )
        {
            return ( this.today.getDate() === date.dateObject.getDate()
                     && this.today.getMonth() === date.dateObject.getMonth()
                     && this.today.getFullYear() === date.dateObject.getFullYear() )
        },

        setViewMode()
        {
            this.mode = 'organizer' === this.plannerMode ? 'week' : this.$props.selectedMode
        },

        handleTargetDayClick( which )
        {
            if( this.$props.selectMode === true
                && this.$props.selectionState === true )
            {
                this.targetDay = which
                this.$core.getEventManager().dispatch( 'on-day-select', which )
            }
        },

        setup()
        {

            let promises = []

            if( undefined !== this.$props.reference )
            {
                promises.push( () =>
                {
                    return this.prepareReferences()
                } )
            }
            promises.push( () =>
            {
                return this.prepareBirthdays()
            } )
            promises.push( () =>
            {
                return this.prepareHolidays()
            } )

            this.$core.f().promiseRunner( promises )
                .then( () =>
                {
                    this.registryReady = true
                    this.prepare()
                    this.refresh( true )
                } )

        },

        prepare( date )
        {

            date = date || new Date()
            let stored    = this.$core.getState( 'planner-date' ),
                timestamp = this.$core.getFriendlyTimestamp().timestampForDate( '1.' + ( 1 + ( date.getMonth() ) ) + '.' + date.getFullYear() )

            this.today = new Date()

            switch( this.mode )
            {
                case 'week':
                    if( undefined !== this.$props.startTsmp )
                    {
                        this.date = new Date( parseInt( this.$props.startTsmp ) )
                    }
                    else
                    {
                        this.date = stored || new Date()
                    }
                    break
                default:
                    this.date = date || new Date( timestamp )
                    break
            }

            this.organizerDate = this.date
            this.prepared = true

        },

        prepareBirthdays()
        {

            return new Promise( resolve =>
            {

                /*eslint-disable*/
                for( const [ s, student ] of this.$core.getBaseClassHelper()
                                                 .get( 'student' )
                                                 .getCache( 'cache' ) )
                {
                    if( this.$core.f().valid( student.birthdate ) )
                    {

                        if( ( this.filterBy === 'student' && this.filterId === student.localId )
                            || ( this.filterBy === 'class' && this.filterId === student.classId )
                            || this.filterBy === false )
                        {

                            let birthday = student.birthdate
                            this.birthdays.push( {
                                birthday: birthday,
                                student : student
                            } )

                        }
                    }
                }
                /*eslint-enable*/

                return resolve()

            } )

        },

        prepareHolidays()
        {

            return new Promise( resolve =>
            {

                this.holidays = []
                this.schoolHolidays = []

                this.$core.getDatabase().readAllObjectsFiltered( 'holidays' )
                    .then( list =>
                    {

                        if( 0 < list.length )
                        {
                            let holidays = list.shift()
                            for( let h in holidays.object )
                            {
                                let hol = holidays.object[ h ]
                                if( 'object' === typeof hol )
                                {
                                    this.holidays.push( hol )
                                }
                            }

                        }

                        this.$core.getDatabase().readAllObjectsFiltered( 'schoolHolidays' )
                            .then( list =>
                            {
                                if( 0 < list.length )
                                {
                                    let holidays = list.shift()
                                    for( let h in holidays.object )
                                    {
                                        let hol = holidays.object[ h ]
                                        if( 'object' === typeof hol )
                                        {
                                            this.schoolHolidays.push( hol )
                                        }
                                    }
                                }

                                return resolve()

                            } )
                    } )

            } )

        },

        refresh( full )
        {

            delete this.organizerDates
            this.organizerDates = []

            if( full )
            {
                this.month = this.date.getMonth()
                this.year = this.date.getFullYear()
                //this.date = new Date( this.$core.getFriendlyTimestamp().timestampForDate( '01.' + ( 1 + this.month ) + '.' + this.year, 12 ) )
                this.organizerMonth = this.organizerDate.getMonth()
                this.organizerYear = this.organizerDate.getFullYear()
            }

            this.prepareDateGrid()
            this.prepareDateElements()

            this.changeKey = this.$core.getUuid().generate()
            this.mounted = true

        },

        dragChange( dragging, direction )
        {
            if( dragging && ( Date.now() > this.lastDragChange + 1000 ) )
            {
                this.lastDragChange = Date.now()
                this.changeDate( direction )
            }
        },

        changeDate( direction, returnValue, target )
        {

            target = target || 'calendar'

            let newYear  = target === 'calendar' ? this.year : this.organizerYear,
                newMonth = target === 'calendar' ? this.month : this.organizerMonth,
                timestamp

            switch( this.mode )
            {
                case 'month':
                    switch( direction )
                    {
                        case 'last':
                            newMonth -= 1
                            break
                        case 'next':
                            newMonth++
                            break
                    }

                    timestamp = this.$core.getFriendlyTimestamp().timestampForDate( '1.' + ( 1 + newMonth ) + '.' + newYear )
                    break
                case 'week':
                    timestamp = target === 'calendar' ? this.date.getTime() : this.organizerDate.getTime()
                    switch( direction )
                    {
                        case 'last':
                            timestamp -= ( 86400000 * 7 )
                            break
                        case 'next':
                            timestamp += ( 86400000 * 7 )
                            break
                    }

                    break
            }

            if( undefined === returnValue )
            {
                switch( target )
                {
                    case 'calendar':
                        this.date = new Date( timestamp )
                        this.$core.setState( 'planner-date', this.date )
                        break
                    case 'organizer':
                        this.organizerDate = new Date( timestamp )
                        break
                }
                this.refresh( true )

            }
            else
            {
                return timestamp
            }

        },

        getMonday( d )
        {

            let day  = d.getDay(),
                diff = d.getDate() - day + ( day == 0 ? -6 : 1 )

            return new Date( d.setDate( diff ) ).getTime()

        },

        prepareDateGrid()
        {

            let fillersBefore = 0,
                fillersAfter  = 0,
                halfDay       = 86400000 / 2,
                scopes        = [ 'calendar', 'organizer' ],
                after,
                start,
                organizerStart,
                daysTotal,
                daysInMonth,
                realStart,
                realEnd

            switch( this.mode )
            {
                case 'month':
                    this.date = new Date( this.$core.getFriendlyTimestamp().timestampForDate( '1.' + ( 1 + this.month ) + '.' + this.year ) )
                    fillersBefore = this.date.getDay() !== 0 ? ( this.date.getDay() - 1 ) : 6
                    after = ( this.changeDate( 'next', true ) - 86400000 )
                    fillersAfter = 7 - new Date( after ).getDay()
                    daysInMonth = new Date( this.year, ( this.month + 1 ), 0 ).getDate()
                    start = this.$core.getFriendlyTimestamp().timestampForDate( '1.' + ( 1 + this.month ) + '.' + this.year ) - ( fillersBefore * 86400000 )
                    organizerStart = this.$core.getFriendlyTimestamp().timestampForDate( '1.' + ( 1 + this.organizerMonth ) + '.' + this.organizerYear ) - ( fillersBefore * 86400000 )
                    daysTotal = fillersBefore + fillersAfter + daysInMonth
                    realStart = new Date( this.$core.getFriendlyTimestamp().timestampForDate( '1.' + ( 1 + this.month ) + '.' + this.year ) )
                    realEnd = new Date( ( realStart.getTime() + ( ( parseInt( daysInMonth ) - 1 ) * 86400000 ) ) )
                    break
                case 'week':
                    daysInMonth = 7
                    start = this.$core.getFriendlyTimestamp().timestampForDateTime(
                        this.$core.getFriendlyTimestamp().formattedDate( this.getMonday( this.date ) ) + ' 12:00:00' )
                    organizerStart = this.$core.getFriendlyTimestamp().timestampForDateTime(
                        this.$core.getFriendlyTimestamp().formattedDate( this.getMonday( this.organizerDate ) ) + ' 12:00:00' )
                    daysTotal = 7
                    realStart = new Date( start )
                    realEnd = new Date( start + ( 86400000 * daysTotal ) )
                    break
            }

            let daysAfter = 0,
                dayAfter  = new Date( after + 86400000 ),
                dayStart  = new Date( this.date.getTime() )

            realStart.setHours( 12, 0, 0, 0 )
            realEnd.setHours( 12, 0, 0, 0 )

            this.realStart = realStart.getTime()
            this.realEnd = realEnd.getTime()
            this.cacheStart = !this.cacheStart ? this.realStart : this.cacheStart

            switch( dayAfter.getDay() )
            {
                case 1:
                case 3:
                case 5:
                    daysAfter = -1
                    break
                case 6:
                    daysAfter = 1
                    break
            }

            let firstVisBefore = fillersBefore
            switch( dayStart.getDay() )
            {
                case 0:
                    firstVisBefore = fillersBefore - 2
                    break
                case 2:
                case 4:
                case 6:
                    firstVisBefore = fillersBefore - 1
                    break
            }

            this.rangeFrom = this.$core.getFriendlyTimestamp().friendlyDate( start )
            this.rangeUntil = this.$core.getFriendlyTimestamp().friendlyDate( start + ( ( daysTotal - 1 ) * 86400000 ) )

            this.organizerRangeFrom = this.$core.getFriendlyTimestamp().friendlyDate( organizerStart )
            this.organizerRangeUntil = this.$core.getFriendlyTimestamp().friendlyDate( organizerStart + ( ( daysTotal - 1 ) * 86400000 ) )

            let checkStart = new Date( start ),
                checkEnd   = new Date( start + ( ( daysTotal - 1 ) * 86400000 ) )

            checkStart.setHours( 12, 0, 0, 0 )
            checkEnd.setHours( 12, 0, 0, 0 )

            this.visibleStart = checkStart.getTime()
            this.visibleEnd = checkEnd.getTime()

            for( let i = 0; i < daysTotal; i++ )
            {

                for( let s in scopes )
                {

                    let scope           = scopes[ s ],
                        calcStart       = scope === 'calendar' ? start : organizerStart,
                        dateObject      = new Date( calcStart ),
                        dateKey         = new Date( calcStart ),
                        isFiller        = i < fillersBefore || i >= ( fillersBefore + daysInMonth ),
                        twoRowVis       = false,
                        firstVisTwoRows = firstVisBefore,
                        lastVisTwoRows  = fillersBefore + daysInMonth + daysAfter

                    dateObject.setHours( 12, 0, 0, 0 )
                    dateKey.setHours( 12, 0, 0, 0 )

                    if( isFiller )
                    {
                        twoRowVis = i >= firstVisTwoRows && i <= lastVisTwoRows
                    }

                    let date = {
                        dateObject: dateObject,
                        timestamp : dateKey.getTime(),
                        day       : dateObject.getDate(),
                        isFiller  : isFiller,
                        twoRowVis : twoRowVis ? ' filler-visible ' : ( isFiller ? ' filler-invisible ' : '' ),
                        dateRange : { from: ( calcStart - halfDay ), until: ( calcStart + halfDay ) },
                        elements  : []
                    }

                    if( false !== this.showDate
                        && this.showDate.dateRange.from === date.dateRange.from )
                    {
                        this.prepareForDate( date )
                        this.showDate = date
                    }

                    let diff
                    switch( scope )
                    {
                        case 'calendar':
                            if( !this.isListed( date.timestamp ) )
                            {
                                this.dates.push( date )
                            }
                            start += 86400000
                            diff = 12 - parseInt( new Date( start ).getHours() )
                            start += ( diff * 3600000 )
                            break
                        case 'organizer':
                            if( !this.isListed( date.timestamp ) )
                            {
                                this.organizerDates.push( date )
                            }
                            organizerStart += 86400000
                            diff = 12 - parseInt( new Date( organizerStart ).getHours() )
                            organizerStart += ( diff * 3600000 )
                            break
                    }

                }

            }

            this.$core.getSorter().multiSortObjects( this.dates, [ [ 'timestamp', 'ascending' ] ] )

        },

        isListed( timestamp )
        {
            for( let d in this.dates )
            {
                if( timestamp === this.dates[ d ].timestamp )
                {
                    return true
                }
            }
            return false
        },

        prepareDateElements()
        {
            for( let d in this.dates )
            {

                let date = this.dates[ d ]
                this.prepareForDate( date )

            }
        },

        prepareReferences()
        {

            return new Promise( resolve =>
            {

                this.$core.getBaseClassHelper()
                    .get( 'group' )
                    .getPreparedCache()
                    .then( allGroups =>
                    {

                        let groupIds = [],
                            scopes   = [ 'cache', 'archive' ]

                        for( let s in scopes )
                        {
                            for( const [ localId, group ] of allGroups[ scopes[ s ] ] )
                            {
                                if( -1 < group.students.indexOf( this.$props.reference.localId ) )
                                {
                                    groupIds.push( localId )
                                }
                            }
                        }

                        this.references.groups = groupIds

                        return resolve()

                    } )

            } )

        },

        referenceMatch( element )
        {

            if( undefined !== this.$props.reference )
            {

                let reference = this.$props.reference

                if( element.groupReference === reference.localId
                    || element.yeargroupReference === reference.localId
                    || element.classReference === reference.localId
                    || element.studentReference === reference.localId
                    || ( undefined !== element.studentReference && -1 < element.studentReference.indexOf( reference.localId ) ) )
                {
                    return true
                }

                if( this.$core.f().isset( reference.classId )
                    && element.classReference === reference.classId )
                {
                    return true
                }

                if( 'student' === reference.type )
                {
                    if( element.type === 'list'
                        && ( element.columns[ 0 ].filterBy === reference.classId
                             || 'all' === element.columns[ 0 ].filterBy
                             || -1 < this.references.groups.indexOf( element.columns[ 0 ].filterBy ) ) )
                    {
                        return true
                    }
                }
                else
                {
                    if( element.type === 'list' )
                    {
                        if( 'all' === element.columns[ 0 ].filterBy
                            || element.columns[ 0 ].filterBy === reference.localId )
                        {
                            return true
                        }
                    }
                }

                return false

            }

        },

        prepareForDate( dateElement )
        {

            let dateElms = [],
                dayCheck = new Date( dateElement.dateRange.from )

            for( let h in this.holidays )
            {

                let holi = this.holidays[ h ],
                    temp = holi.date.split( '-' )

                if( parseInt( temp[ 2 ] ) === parseInt( dayCheck.getDate() )
                    && parseInt( temp[ 1 ] ) === ( parseInt( dayCheck.getMonth() ) + 1 )
                    && parseInt( temp[ 0 ] ) === ( parseInt( dayCheck.getFullYear() ) ) )
                {

                    let date = {
                        title      : holi.fname,
                        start      : '-',
                        description: 'Feiertag',
                        color      : 'holidays',
                        isHoliday  : true,
                        isMultiDay : 200,
                        isBirthday : false,
                        editLocked : true,
                        type       : 'date',
                        timestamp  : this.$core.getFriendlyTimestamp().timestampFromMysql( holi.date + ' 12:00:00' )
                    }

                    dateElms.push( date )

                }

            }

            let checkTime = dayCheck.getTime()

            for( let h in this.schoolHolidays )
            {

                let holi = this.schoolHolidays[ h ]
                let name = this.$core.f().ucFirst( holi.name )

                let start = this.$core.getFriendlyTimestamp().timestampForDateTime(
                    this.$core.getFriendlyTimestamp().formattedDate(
                        this.$core.getFriendlyTimestamp().timestampFromMysql( this.$core.getFriendlyTimestamp().convertServerTimestamp( holi.start ) )
                    ) + ' 00:00:00'
                )
                let end = this.$core.getFriendlyTimestamp().timestampForDateTime(
                    this.$core.getFriendlyTimestamp().formattedDate(
                        this.$core.getFriendlyTimestamp().timestampFromMysql( this.$core.getFriendlyTimestamp().convertServerTimestamp( holi.end ) )
                    ) + ' 23:59:59'
                )

                if( checkTime >= start
                    && checkTime <= end )
                {

                    let date = {
                        title          : name,
                        start          : '-',
                        description    : 'Schulferien',
                        color          : 'school-holidays',
                        isHoliday      : false,
                        timestamp      : start,
                        enddate        : this.$core.getFriendlyTimestamp().formattedDate( end ),
                        isSchoolHoliday: true,
                        isBirthday     : false,
                        isMultiDay     : 200,
                        editLocked     : true,
                        type           : 'date'
                    }

                    dateElms.push( date )

                }

            }

            let multidays = 100
            let todo = [ 'dates' ] // 'notes', 'todos' ]
            if( this.$core.settings().getSetting( 'calendarShowNotes' ) )
            {
                todo.push( 'notes' )
            }
            if( this.$core.settings().getSetting( 'calendarShowTodos' ) )
            {
                todo.push( 'todos' )
            }

            for( let t in todo )
            {

                let step = todo[ t ]

                /*eslint-disable*/
                for( const [ e, elm ] of this.$core.getBaseClassHelper()
                                             .get( this.translateType( step ) )
                                             .getCache( 'cache' ) )
                {

                    let checkStamp    = elm.timestamp,
                        checkStampEnd = elm.timestamp

                    if( 'todos' === step )
                    {
                        checkStamp = elm.duedate
                    }
                    if( 'dates' !== step )
                    {
                        elm.start = "99:99"
                    }

                    let isMultiDay = this.$core.f().isset( elm.enddate )
                                     && '' !== elm.enddate.trim()
                                     && this.$core.getValidator().validate( 'date', false, elm.enddate )

                    elm.isMultiDay = isMultiDay ? multidays : 0
                    let dayCount = 1

                    if( isMultiDay )
                    {

                        multidays++
                        let endTime = '23:59:59'
                        checkStamp = this.$core.getFriendlyTimestamp().timestampForDateTime(
                            this.$core.getFriendlyTimestamp().formattedDate( checkStamp ) + ' 00:00:00' )
                        checkStampEnd = this.$core.getFriendlyTimestamp().timestampForDateTime( elm.enddate + ' ' + endTime )
                        dayCount = Math.ceil( ( checkStampEnd - checkStamp ) / 86400000 )

                    }

                    elm.dayCount = dayCount

                    if( ( !isMultiDay && ( checkStamp >= dateElement.dateRange.from
                          && checkStamp < dateElement.dateRange.until ) )
                        || ( isMultiDay && ( checkStamp <= dateElement.dateRange.from
                             && dateElement.dateRange.until <= ( checkStampEnd + 1000 ) ) ) )
                    {

                        if( !this.$core.f().isset( this.$props.reference )
                            || this.referenceMatch( elm ) )
                        {
                            dateElms.push( elm )
                        }

                    }

                }
                /*eslint-enable*/
            }

            for( let b in this.birthdays )
            {
                let birthday = this.birthdays[ b ]

                let temp = birthday.birthday.split( '.' )
                if( parseInt( temp[ 0 ] ) === parseInt( dayCheck.getDate() )
                    && parseInt( temp[ 1 ] ) === ( parseInt( dayCheck.getMonth() ) + 1 ) )
                {

                    let age = parseInt( dayCheck.getFullYear() ) - parseInt( temp[ 2 ] )
                    let date = {
                        title      : birthday.student.lastname + ', ' + birthday.student.firstname,
                        start      : '-',
                        age        : age,
                        description: 'wird <strong>' + age + '</strong> Jahre alt' + ( undefined !== birthday.student.classId ? '<br/>Klasse: <strong>' + birthday.student.classname + '</strong>' : '' ),
                        color      : 'birthday-' + birthday.student.gender,
                        isBirthday : true,
                        editLocked : true,
                        timestamp  : this.$core.friendlyTimestamp.timestampForDate( birthday.birthday ),
                        type       : 'date'
                    }

                    dateElms.push( date )

                }
            }

            if( this.$core.settings().getSetting( 'calendarShowLists' ) )
            {

                /*eslint-disable*/
                for( const [ e, elm ] of this.$core.getBaseClassHelper()
                                             .get( 'list' )
                                             .getCache( 'cache' ) )
                {

                    for( let l in elm.lists )
                    {
                        if( elm.lists[ l ].timestamp >= dateElement.dateRange.from
                            && elm.lists[ l ].timestamp < dateElement.dateRange.until )
                        {

                            if( !this.$core.f().isset( this.$props.reference )
                                || this.referenceMatch( elm.lists[ l ] ) )
                            {
                                dateElms.push( elm.lists[ l ] )
                            }

                        }
                    }

                }
                /*eslint-enable*/

            }

            let sortRules = [
                [ 'start', 'ascending' ],
                [ 'isMultiDay', 'descending' ],
                [ 'dayCount', 'descending' ],
                [ 'type', 'ascending' ],
                [ 'isHoliday', 'descending' ],
                [ 'isSchoolHoliday', 'descending' ]
            ]

            this.$core.sort().multiSortObjects( dateElms, sortRules, true )
            //this.$core.sort().multiSortObjects( dateElms, sortRules, true )
            dateElement.elements = dateElms

        },

        hookKeys( state )
        {

            switch( state )
            {
                case undefined:
                    document.onkeydown = ( event ) =>
                    {

                        if( !event.shiftKey && 'ArrowLeft' === event.key )
                        {
                            this.changeDate( 'last' )
                        }
                        if( !event.shiftKey && 'ArrowRight' === event.key )
                        {
                            this.changeDate( 'next' )
                        }
                        if( '+' === event.key )
                        {
                            this.$emit( 'add' )
                        }

                    }
                    break
                case false:
                    document.onkeydown = null
                    break
            }

        },

        handleShowPlannerDay( showDate )
        {
            this.showDate = showDate
            this.showPlannerDay = true
        },

        handleClosePlannerDay()
        {
            this.showPlannerDay = false
            this.showDate = false
            this.$nextTick()
                .then( () =>
                {
                    this.hookKeys( false )
                    this.hookKeys()
                } )
        },

        handleChangePlannerDay( direction )
        {

            let newDay = this.showDate.dateRange.from
            switch( direction )
            {
                case 'last':
                    newDay -= 86400000
                    break
                case 'next':
                    newDay += 86400000
                    break
            }

            let dateObject = new Date( newDay + ( 86400000 / 2 ) )

            let date = {
                dateObject: dateObject,
                day       : dateObject.getDate(),
                isToday   : ( this.today.getDate() === dateObject.getDate() && this.today.getMonth() === dateObject.getMonth() && this.today.getFullYear() === dateObject.getFullYear() ),
                dateRange : { from: newDay, until: ( newDay + 86400000 ) },
                elements  : []
            }

            this.prepareForDate( date )
            this.showDate = date

            this.changeKey = this.$core.getUuid().generate()

        },

        handleRefresh( parent )
        {
            if( undefined === parent )
            {
                this.refresh()
            }
            else
            {
                this.$emit( 'refresh' )
            }
        }

        /*eslint-disable*/
    }

}


</script>