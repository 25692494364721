<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )" mode="out-in">
        <div v-if="visible && false !== setup" class="default-form-overlay statistics">
            <div class="default-form-container" v-if="active">
                <div class="default-form-wrapper">
                    <Button type="close"
                            @clicked="handleClose()"
                            addClass="absolute top right inverted"/>
                    <h2>Diagramm: <strong>{{ setup.item.listname }}</strong></h2>
                    <div class="clearfix"></div>
                    <div class="spacer"></div>
                    <div :class="'openable-content'+( open ? ' open' : '' )" id="toggle-statistics-view">
                        <Opener :inline="true"
                                id="statistics-view"
                                caption="Einstellungen"
                                classname="toggleable"
                                :state="open"
                                @clicked="open = !open"/>
                        <div v-if="open" class="open-content no-padding relative">
                            <div class="form inline">
                                <div class="form-row">
                                    <div class="form-caption">Diagramm-<strong>Typ</strong></div>
                                    <div class="form-component">
                                        <div class="select-wrapper">
                                            <select name="statistics-mode-selector" @change="setMode">
                                                <option value="bar" :selected="'bar' === mode">Balkendiagramm</option>
                                                <option value="lines" :selected="'lines' === mode">Liniendiagramm
                                                </option>
                                                <option value="radar" :selected="'radar' === mode">Netzdiagramm</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-caption">Achsen <strong>vertauschen</strong></div>
                                    <div class="form-component">
                                        <Checkbox key="checkInvert"
                                                  refName="checkInvert"
                                                  :value="true"
                                                  :caption="''"
                                                  @update="handleInvert()"/>
                                    </div>
                                    <div class="form-caption">auszuwertende <strong>Versionen</strong></div>
                                    <div class="form-component">
                                        <Checkbox v-for="list in listList"
                                                  :key="'kcheck-'+list.localId+'-'+list.timestamp"
                                                  :refName="'check-'+list.localId+'-'+list.timestamp"
                                                  :value="checked[list.timestamp]"
                                                  :caption="'<strong>'+$core.getFriendlyTimestamp().friendlyDate( list.timestamp ) +'</strong>'"
                                                  @update="handleVersionCheck( list.timestamp, $event )"/>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                    <div class="spacer"></div>
                    <div class="form inline rounded">
                        <BarDiagram v-if="'bar' === mode" :chartdata="chartdata" :options="options"
                                    :changeKey="changeKey"
                                    :colorset="colorset" :bordercolors="borderColors"/>
                        <LineDiagram v-if="'lines' === mode" :chartdata="chartdata" :options="options"
                                     :changeKey="changeKey"
                                     :colorset="colorset" :bordercolors="borderColors"/>
                        <RadarDiagram v-if="'radar' === mode" :chartdata="chartdata" :options="options"
                                      :changeKey="changeKey"
                                      :colorset="colorset" :bordercolors="borderColors" @forceReset="handleReset()"/>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
/*eslint-disable*/
import BarDiagram   from '@/components/defaults/overlays/statistics/BarDiagram'
import LineDiagram  from '@/components/defaults/overlays/statistics/LineDiagram'
import RadarDiagram from '@/components/defaults/overlays/statistics/RadarDiagram'
import Opener       from '@/components/elements/defaults/Opener'
import Checkbox     from '@/components/form/elements/Checkbox'

export default {

    name      : 'StatisticsOverlay',
    components: { Checkbox, Opener, RadarDiagram, LineDiagram, BarDiagram },
    emits     : [ 'close', 'preset', 'forceReset' ],

    props: {
        setup : { type: [ Object, Boolean ], required: true },
        preset: { type: [ Object, Boolean ], required: false }
    },

    data()
    {
        return {
            visible     : false,
            active      : true,
            open        : false,
            mode        : 'bar',
            axesInverted: true,
            colorset    : [],
            borderColors: [],
            options     : {
                font               : { family: 'Quicksand', weight: 900 },
                responsive         : true,
                plugins            : {
                    tooltips: { mode: 'index', intersect: false },
                    hover   : { mode: 'index', intersect: false },
                    legend  : {
                        labels: {
                            font: {
                                family: 'Quicksand',
                                size  : 16
                            }
                        }
                    },
                },
                scale              : {
                    min  : 0,
                    ticks: {
                        beginAtZero: true,
                        stepSize   : 1,
                        font       : {
                            size: 12
                        }
                    }
                },
                maintainAspectRatio: true
            },
            checked     : {},
            inData      : [],
            listList    : [],
            labels      : false,
            chartdata   : false,
            changeKey   : false
        }
    },

    created()
    {
        this.prepare()
        this.initColors()
        this.labels = this.getLabels()
        this.getDatasets()
            .then( data =>
            {
                this.chartdata = data
                this.visible = true
            } )
    },

    mounted()
    {
        if( undefined !== this.$props.preset )
        {
            this.open = true
            this.mode = this.$props.preset.mode
        }
    },

    methods: {

        initColors( override )
        {

            this.colorset = []
            this.borderColors = []

            let colorCount = override ? ( override * 2 ) : ( this.inData.length * 2 ) + 1
            let frequency = 5 / colorCount
            for( let c = 0; c < colorCount; ++c )
            {
                let r = Math.floor( Math.sin( frequency * c + 0 ) * ( 127 ) + 128 );
                let g = Math.floor( Math.sin( frequency * c + 2 ) * ( 127 ) + 128 );
                let b = Math.floor( Math.sin( frequency * c + 4 ) * ( 127 ) + 128 );
                let hex = r.toString( 16 ).padStart( 2, '0' ) + g.toString( 16 ).padStart( 2, '0' ) + b.toString( 16 ).padStart( 2, '0' )
                if( c % 2 === 0 )
                {
                    this.colorset.push( '#' + hex )
                }
                else
                {
                    this.borderColors.push( '#' + hex )
                }
            }
        },

        prepare()
        {

            this.changeKey = this.$core.getUuid().generate()
            let sortRules = this.$core.settings().getSetting( 'sortingDirections' )
            this.listList = this.$props.setup.item.lists
            this.$core.sort().multiSortObjects( this.listList, sortRules[ 'lists' ] )

            for( let l in this.listList )
            {
                let list = this.listList[ l ]
                this.inData.push( list )
                this.checked[ list.timestamp ] = 'on'

            }

        },

        handleReset()
        {
            this.active = false
            this.$nextTick()
                .then( () =>
                {
                    this.$emit( 'forceReset' )
                } )
        },

        handleInvert()
        {

            this.axesInverted = !this.axesInverted
            if( !this.axesInverted )
            {
                this.initColors()
            }

            this.getDatasets().then( data =>
            {
                this.chartdata = data
                this.changeKey = this.$core.getUuid().generate()
            } )

        },

        handleVersionCheck( timestamp, elementId )
        {

            let element = document.querySelector( '#form-element-' + elementId )
            if( null !== element )
            {
                let state = element.checked
                this.checked[ timestamp ] = state ? 'on' : 'off'
            }

            this.getDatasets().then( data =>
            {
                this.chartdata = data
                this.changeKey = this.$core.getUuid().generate()
            } )

        },

        setMode( event )
        {

            this.$emit( 'preset', { mode: event.target.value } )

            this.mode = false
            this.$nextTick()
                .then( () =>
                {
                    this.mode = event.target.value
                } )

        },

        getLabels()
        {

            let labels = []
            let columns = this.inData[ 0 ].columns
            for( let c in columns )
            {
                if( 'fixed' !== columns[ c ].type
                    && 'textarea' !== columns[ c ].type
                    && 'textbox' !== columns[ c ].type
                    && undefined !== columns[ c ].type )
                {
                    labels.push( columns[ c ].caption )
                }
            }

            return labels

        },

        getDatasets()
        {

            return new Promise( resolve =>
            {

                if( this.axesInverted )
                {
                    return resolve( this.getInvertedDatasets() )
                }

                let datasets = [],
                    sortRules = this.$core.settings().getSetting( 'sortingDirections' )

                console.log( 'JJJ', 'DATASETS' )
                this.$core.sort().multiSortObjects( this.inData, sortRules[ 'statistics' ] )
                console.log( 'JJJ', this.inData )

                this.$core.getTableCalculation()
                    .getSummary( this.inData )
                    .then( summary =>
                    {

                        for( let l in summary.result )
                        {

                            let list = summary.result[ l ]
                            if( this.checked[ list.timestamp ] === 'on' )
                            {

                                let dataset = []
                                for( let c in list.columns )
                                {
                                    if( undefined !== list.columns[ c ].type
                                        && 'textarea' !== list.columns[ c ].type
                                        && 'textbox' !== list.columns[ c ].type )
                                    {
                                        let colId = this.$core.getSanitizers().cleanId( list.columns[ c ].caption ) + '___' + ( parseInt( c ) + 1 )
                                        dataset.push( list.summary.summary.byColumn[ colId ] )
                                    }
                                }

                                datasets.push( {
                                    data : dataset,
                                    label: this.$core.getFriendlyTimestamp().friendlyDate( list.timestamp ),
                                } )

                            }

                        }

                        let prepared = {
                            labels  : this.labels,
                            datasets: []
                        }

                        for( let d in datasets )
                        {

                            let prep = {},
                                data = datasets[ d ]

                            prep.data = data.data
                            prep.label = data.label
                            prep.borderColor = this.borderColors[ d ]
                            prep.backgroundColor = this.$core.f().hexToRgbA( this.colorset[ d ], 0.5 )

                            prepared.datasets.push( prep )

                        }

                        return resolve( prepared )

                    } )

            } )

        },

        getInvertedDatasets()
        {

            return new Promise( resolve =>
            {

                let datasets = []
                let sortRules = this.$core.settings().getSetting( 'sortingDirections' )

                this.$core.sort().multiSortObjects( this.inData, sortRules[ 'statistics' ] )

                this.$core.getTableCalculation()
                    .getSummary( this.inData )
                    .then( summary =>
                    {


                        let labels = []
                        let def = summary.result[ 0 ]
                        let first = true

                        for( let c in def.columns )
                        {

                            if( undefined !== def.columns[ c ].type
                                && 'textarea' !== def.columns[ c ].type
                                && 'textbox' !== def.columns[ c ].type )
                            {

                                let dataset = []

                                for( let l in summary.result )
                                {
                                    let list = summary.result[ l ]
                                    if( this.checked[ list.timestamp ] === 'on' )
                                    {
                                        let colId = this.$core.getSanitizers().cleanId( list.columns[ c ].caption ) + '___' + ( parseInt( c ) + 1 )
                                        dataset.push( list.summary.summary.byColumn[ colId ] )
                                        if( first )
                                        {
                                            labels.push( this.$core.getFriendlyTimestamp().friendlyDate( list.timestamp ) )
                                        }
                                    }
                                }

                                first = false

                                datasets.push( {
                                    data : dataset,
                                    label: def.columns[ c ].caption,
                                } )

                            }

                        }

                        this.initColors( datasets.length )

                        let prepared = {
                            labels  : labels,
                            datasets: []
                        }

                        for( let d in datasets )
                        {

                            let prep = {},
                                data = datasets[ d ]

                            prep.data = data.data
                            prep.label = data.label
                            prep.borderColor = this.borderColors[ d ]
                            prep.backgroundColor = this.$core.f().hexToRgbA( this.colorset[ d ], 0.5 )

                            prepared.datasets.push( prep )

                        }

                        return resolve( prepared )

                    } )
            } )

        },

        handleClose()
        {
            this.visible = false
            this.$core.setState( 'statsClosing', true )
            setTimeout( () =>
            {
                this.active = false
                this.$emit( 'close' )
            }, 300 )
        }

    }
}
</script>