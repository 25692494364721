<script>
export default {
    name: "MixinPagination",

    data()
    {
        return {
            paginating   : false,
            displayedPage: 1,
            waitingEmit  : false,
            pages        : []
        }
    },

    created()
    {
        this.preparePagination()
        this.$core.getEventManager()
            .add( 'on-paginate', () =>
            {
                this.preparePagination()
            } )
    },

    beforeUnmount()
    {
        this.$core.getEventManager()
            .remove( 'on-paginate' )
    },

    emits: [ 'requestUpdate' ],

    computed: {
        watchKey()
        {
            return this.$props.filterKey + '-' + this.$props.viewKey + '-' + this.$props.hiddenVisible
        },
        cacheKey()
        {
            return this.$core.getBaseClassHelper().getCacheKey( this.$core.f().lcFirst( this.$props.viewItem ) )
        }
    },

    watch: {
        watchKey         : {
            handler( newValue, oldValue )
            {
                if( 'false-false-false-false' === oldValue
                    || 'false-undefined-false' === oldValue )
                {
                    return
                }
                if( undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.preparePagination()
                }
            }
        },
        controlKey       : {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && false !== oldValue
                    && newValue !== oldValue
                    && 'false-false' !== newValue )
                {
                    this.preparePagination()
                }
            }
        },
        viewControlKey       : {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && false !== oldValue
                    && newValue !== oldValue
                    && 'false-false' !== newValue )
                {
                    this.preparePagination()
                }
            }
        },
        cacheKey         : {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( false !== oldValue
                    && undefined !== oldValue
                    && newValue !== oldValue )
                {
                    if( true !== this.$core.getState( 'listEditorOpen' ) )
                    {
                        this.$emit( 'requestUpdate' )
                    }
                }
            }
        },
        'itemList.length': {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( oldValue > newValue )
                {
                    this.preparePagination()
                }
            }
        }
    },

    /*eslint-disable*/

    methods: {

        preparePagination()
        {

            let cache = this.$core.f().lcFirst( this.$props.viewItem )

            if( this.paginating )
            {
                return
            }

            this.paginating = true

            let max   = parseInt( this.$core.settings().getSetting( 'elementCount' ) ),
                count = 0,
                page  = [],
                pages = []

            for( let i in this.$props.itemList )
            {

                if( ( !this.$core.getBaseClassHelper().isElementHidden( this.$props.itemList[ i ], cache ) || true === this.$props.hiddenVisible )
                    && ( -1 < this.$props.filtered.indexOf( this.$props.itemList[ i ] ) || !this.$props.viewIsFiltered ) )
                {

                    page.push( this.$props.itemList[ i ] )
                    count++
                    if( count === max )
                    {
                        pages.push( page )
                        page = []
                        count = 0
                    }

                }

            }

            if( page.length > 0 )
            {
                pages.push( page )
            }

            if( page.length === 0 && this.pages.length === 0 )
            {
                this.displayedPage = 1
                pages.push( [] )
            }

            this.pages = pages
            this.displayedPage = false === this.displayedPage ? 1 : this.displayedPage
            this.paginating = false

        },

        handlePageChange( page )
        {
            this.displayedPage = page
        }

    }
}
</script>