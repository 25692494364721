<template>
    <span :key="'lastEditor-'+elementKey" v-if="false !== lastEditor && 'combilist' !== reference.listType"
          v-html="lastEditor"></span>
</template>

<script>

import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {

    name  : 'LastEditor',
    mixins: [ MixinCachePreheater ],

    props: {
        reference : { Type: Object, required: true },
        elementKey: { Type: String, required: true },
        isTemplate: { Type: Boolean, required: false, default: false }
    },

    data()
    {
        return {
            neededCaches: [ 'colleague' ],
            lastEditor  : false
        }
    },

    watch: {
        elementKey: {
            immediate: true,
            handler( newKey, oldKey )
            {
                if( oldKey !== undefined
                    && newKey !== oldKey )
                {
                    this.prepareLastEditor()
                }
            }
        }
    },

    mounted()
    {
        this.awaitNeededCaches()
            .then( () =>
            {
                this.prepareLastEditor()
            } )
    },

    methods: {

        prepareLastEditor()
        {

            if( this.$props.isTemplate === true )
            {
                this.lastEditor = '&nbsp;von <strong>entzettelt</strong>'
                return
            }

            let colleagues = this.$core.getBaseClassHelper()
                                 .get( 'colleague' )
                                 .getCache( 'cache' )

            if( undefined === colleagues
                || 0 === colleagues.size )
            {
                return
            }

            let lastEditor = undefined !== this.$props.reference.lasteditor ? parseInt( this.$props.reference.lasteditor ) : undefined,
                ownId      = parseInt( this.$store.getters.idUser )

            if( this.$props.reference.update === undefined )
            {
                lastEditor = this.$props.reference.idOwner
            }

            if( lastEditor !== ownId
                && undefined !== lastEditor )
            {

                let colleague = this.$core.getBaseClassHelper()
                                    .get( 'colleague' )
                                    .getColleagueById( lastEditor )

                if( colleague )
                {
                    this.lastEditor = '&nbsp;von <strong>' + colleague.firstname + ' ' + colleague.lastname + '</strong>'
                }
                else
                {
                    this.lastEditor = '&nbsp;von <strong>unbekannt</strong>'
                }

            }
            else if( lastEditor === ownId )
            {
                this.lastEditor = '&nbsp;von <strong>mir</strong>'
            }
            else
            {
                this.lastEditor = false
            }
        }

    }

}
</script>