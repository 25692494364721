import ChecklistDefinition  from '@/classes/TableCalculation/definitions/Checklist'
import RatinglistDefinition from '@/classes/TableCalculation/definitions/Ratinglist'
import FlexlistDefinition   from '@/classes/TableCalculation/definitions/FlexlistDefinition'
import RecalllistDefinition from '@/classes/TableCalculation/definitions/RecalllistDefinition'
import TestlistDefinition   from '@/classes/TableCalculation/definitions/TestlistDefinition'

export default class TableCalculation
{
    constructor( core )
    {
        if( !TableCalculation.instance )
        {

            this.logger = core.getLogger()
            this.sanitizers = core.getSanitizers()
            this.translation = core.getTranslation()
            this.reformatter = core.getReformatter()
            this.settings = core.settings()
            this.store = core.getStore()
            this.baseClassHelper = core.getBaseClassHelper()
            this.eventManager = core.getEventManager()
            this.friendlyTimestamp = core.getFriendlyTimestamp()

            this.filterId = undefined
            this.filterList = false

            this.f = core.f()

            this.getObjectById = ( which ) =>
            {
                return core.getBaseClassHelper().getObjectById( which )
            }

            this.studentBaseClass = null
            this.eventManager.append( 'on-baseclasses-available', () =>
            {
                this.studentBaseClass = core.getBaseClassHelper().get( 'student' )
            } )

            this.getObjectRespectingTimestamp = ( which, timestamp ) =>
            {
                return core.getBaseClassHelper().getObjectRespectingTimestamp( which, timestamp )
            }

            this.getState = ( id =>
            {
                return core.getState( id )
            } )

            TableCalculation.instance = this
        }
        return TableCalculation.instance
    }

    destruct()
    {
        delete TableCalculation.instance
    }

    filterMatch( column, timestamp )
    {

        let student = this.getObjectById( column.localId )

        if( undefined === student
            || false === student
            || !this.f.archiveMatch( student, timestamp )
            || 'student' !== student.type )
        {
            return false
        }

        if( !this.filterList )
        {
            return true
        }

        let filterMatch = true

        for( let f in this.filterList )
        {
            let filter = this.filterList[ f ],
                group  = this.getObjectById( filter.value ),
                row    = this.getObjectById( column.localId )

            switch( filter.attr )
            {
                case 'groupId':
                    if( -1 === group.students.indexOf( row.localId ) )
                    {
                        filterMatch = false
                    }
                    break
                default:
                    if( row[ filter.attr ] !== filter.value )
                    {
                        filterMatch = false
                    }
                    break
            }

        }

        return filterMatch

    }

    getFieldDefinition( lists, type )
    {

        if( Array.isArray( lists )
            && 0 < lists.length )
        {

            let fieldset

            switch( lists[ 0 ].listType )
            {
                case 'checklist':
                    fieldset = ChecklistDefinition.fieldset[ type ]
                    break
                case 'ratinglist':
                    fieldset = RatinglistDefinition.fieldset[ type ]
                    break
                case 'customFixed':
                case 'combilist':
                    fieldset = FlexlistDefinition.fieldset[ type ]
                    break
                case 'recallList':
                    fieldset = RecalllistDefinition.fieldset[ type ]
                    break
                case 'test':
                    fieldset = TestlistDefinition.fieldset[ type ]
                    break
                default:
                    this.logger.cerror( 'TableCalculation:getFieldDefinition', 'unknown list type: ', lists[ 0 ].listType )
                    break
            }
            return fieldset

        }

    }

    getSummaryDefinition( lists, type )
    {

        let fieldset
        switch( lists[ 0 ].listType )
        {
            case 'checklist':
                fieldset = ChecklistDefinition.summary[ type ]
                break
            case 'ratinglist':
                fieldset = RatinglistDefinition.summary[ type ]
                break
            case 'customFixed':
            case 'combilist':
                fieldset = FlexlistDefinition.summary[ type ]
                break
            case 'recallList':
                fieldset = RecalllistDefinition.summary[ type ]
                break
            case 'test':
                fieldset = TestlistDefinition.summary[ type ]
                break
            default:
                this.logger.cerror( 'TableCalculation:getSummaryDefinition', 'unknown list type: ', lists[ 0 ].listType )
                break
        }
        return fieldset

    }

    getSummaryDisplay( lists )
    {
        let fieldset
        switch( lists[ 0 ].listType )
        {
            case 'checklist':
                fieldset = ChecklistDefinition.summaryDisplay
                break
            case 'ratinglist':
                fieldset = RatinglistDefinition.summaryDisplay
                break
            case 'customFixed':
            case 'combilist':
                fieldset = FlexlistDefinition.summaryDisplay
                break
            case 'recallList':
                fieldset = RecalllistDefinition.summaryDisplay
                break
            case 'test':
                fieldset = TestlistDefinition.summaryDisplay
                break
            default:
                this.logger.cerror( 'TableCalculation:getSummaryDisplay', 'unknown list type: ', lists[ 0 ].listType )
                break
        }

        if( undefined === fieldset )
        {
            return 'listElement-textbox'
        }
        return fieldset.component
    }

    getColumns( list, fieldset, noAverages, addCounter )
    {

        let columns = []

        for( let c in list.columns )
        {
            let column = list.columns[ c ]
            if( column.type !== 'fixed' )
            {

                let columnId = column.originalId || this.sanitizers.cleanId( column.caption )

                columns.push( {
                    caption   : column.caption,
                    rawId     : this.sanitizers.cleanId( column.caption ),
                    valueId   : this.sanitizers.cleanId( column.caption ) + '___' + ( parseInt( c ) ),
                    columnId  : ( addCounter ? ( parseInt( c ) - 1 ) + '_' : '' ) + columnId,
                    originalId: column.originalId,
                    type      : column.type,
                    score     : column.score || undefined
                } )
            }
        }

        if( true !== noAverages )
        {
            for( let f in fieldset )
            {
                let columnId = '___' + fieldset[ f ]
                columns.push( {
                    caption : this.translation.translate( columnId ),
                    columnId: columnId
                } )
            }
        }

        return columns

    }

    getCalcType( type )
    {

        let defaultSetting   = this.settings.getSetting( 'listDefaultCalcType-' + type ) || null,
            settingsOverride = this.settings.getSetting( 'listCalcType-' + type ) || null

        if( null !== settingsOverride
            && 'default' !== settingsOverride )
        {
            return settingsOverride
        }

        return defaultSetting || 'count'

    }

    getTotalsType( type )
    {

        let calcType = 'count'

        switch( type )
        {
            case 'scorebox':
            case 'scoreBox':
            case 'rateselector':
                calcType = 'average'
                break
            case 'checkbox':
            case 'testItem':
            case 'numberbox':
                calcType = 'addvalue'
                break
            case 'testcomment':
                calcType = 'ignore'
                break
            case 'threewaytoggle':
                calcType = 'distinct'
                break
        }

        return calcType

    }

    parseColumn( id, columns )
    {

        let temp     = id.split( '___' ),
            localId  = temp.shift(),
            column   = temp.pop(),
            columnId = temp.join( '___' ),
            type     = undefined

        for( let c in columns )
        {
            if( columns[ c ].columnId === columnId )
            {
                type = columns[ c ].type
            }
        }

        return {
            localId : localId,
            columnId: columnId,
            column  : column,
            type    : type,
            calcType: this.getCalcType( type ),
            fullId  : columnId + '___' + column
        }

    }

    prepareSkeleton( columns )
    {

        let result = {
            byRow   : {},
            byColumn: {}
        }

        for( let c in columns )
        {
            let columnId = columns[ c ].columnId + '___' + ( parseInt( c ) + 1 )
            result.byRow[ columnId ] = {}
            result.byColumn[ columnId ] = null
        }

        return result

    }

    /*eslint-disable*/
    getStudentsForList( list, timestamp )
    {

        timestamp = timestamp || list.timestamp

        if( undefined === list.columns )
        {
            return []
        }

        let filter      = list.columns[ 0 ].filterBy,
            tempList    = [],
            tempPrepare = [],
            studentList = []

        if( 'all' === filter )
        {
            tempPrepare = this.studentBaseClass.getCache( list.archived === true ? 'archive' : 'cache' )
            for( const [ i, temp ] of tempPrepare )
            {
                tempList.push( temp )
            }
        }
        else
        {
            let element = this.getObjectById( filter ),
                temp    = element ? element.students : []

            for( let t in temp )
            {
                tempList.push( this.studentBaseClass.getById( temp[ t ] ) )
            }
        }

        for( let t in tempList )
        {
            if( undefined !== tempList[ t ]
                && this.f.archiveMatch( tempList[ t ], timestamp ) )
            {
                studentList.push( tempList[ t ] )
            }
        }

        tempList = null
        return studentList

    }

    getStudentIdsForList( list, timestamp )
    {

        timestamp = timestamp || list.timestamp

        if( undefined === list.columns )
        {
            return []
        }

        let filter      = list.columns[ 0 ].filterBy,
            tempList    = [],
            tempPrepare = [],
            studentList = []

        if( 'all' === filter )
        {
            tempPrepare = this.studentBaseClass.getCache( list.archived === true ? 'archive' : 'cache' )
            for( const [ i, temp ] of tempPrepare )
            {
                tempList.push( temp )
            }
        }
        else
        {
            let element = this.getObjectById( filter ),
                temp    = element ? element.students : []

            for( let t in temp )
            {
                tempList.push( this.studentBaseClass.getById( temp[ t ] ) )
            }
        }

        for( let t in tempList )
        {
            if( undefined !== tempList[ t ]
                && this.f.archiveMatch( tempList[ t ], timestamp ) )
            {
                studentList.push( tempList[ t ].localId )
            }
        }

        tempList = null
        return studentList

    }


    getPossibleRowCount( lists )
    {
        let studentList     = [],
            rowCounts       = {},
            lowestTimestamp = 9999999999999999

        for( let l in lists )
        {
            if( lists[ l ].timestamp < lowestTimestamp )
            {
                lowestTimestamp = lists[ l ].timestamp
            }
        }

        studentList = this.getStudentsForList( lists[ 0 ], lowestTimestamp )

        for( let l in lists )
        {
            let count = 0
            for( let s in studentList )
            {
                if( this.f.archiveMatch( studentList[ s ], lists[ l ].timestamp ) )
                {
                    count++
                }
            }
            rowCounts[ lists[ l ].localId ] = count
        }


        return rowCounts

    }

    prepareSummary( list, columns )
    {

        return new Promise( resolve =>
        {

            let summary  = this.prepareSkeleton( columns ),
                averages = this.prepareSkeleton( columns ),
                counts   = this.prepareSkeleton( columns ),
                distinct = this.prepareSkeleton( columns )

            for( let v in list.values )
            {

                let value      = list.values[ v ],
                    columnInfo = this.parseColumn( v, columns )

                if( ( this.filterId === undefined
                      || columnInfo.localId === this.filterId )
                    && this.filterMatch( columnInfo, list.timestamp ) )
                {

                    if( undefined === summary.byRow[ columnInfo.fullId ] )
                    {
                        summary.byRow[ columnInfo.fullId ] = {}
                        averages.byRow[ columnInfo.fullId ] = {}
                        distinct.byRow[ columnInfo.fullId ] = {}
                    }

                    if( undefined === summary.byRow[ columnInfo.fullId ][ columnInfo.localId ] )
                    {
                        summary.byRow[ columnInfo.fullId ][ columnInfo.localId ] = 0
                        averages.byRow[ columnInfo.fullId ][ columnInfo.localId ] = 0
                        distinct.byRow[ columnInfo.fullId ][ columnInfo.localId ] = {}
                    }

                    if( null === summary.byColumn[ columnInfo.fullId ]
                        || undefined === summary.byColumn[ columnInfo.fullId ] )
                    {
                        summary.byColumn[ columnInfo.fullId ] = 0
                        averages.byColumn[ columnInfo.fullId ] = 0
                        counts.byColumn[ columnInfo.fullId ] = 0
                        distinct.byColumn[ columnInfo.fullId ] = {}
                    }

                    counts.byColumn[ columnInfo.fullId ]++

                    switch( columnInfo.calcType )
                    {
                        case 'addvalue':
                            summary.byRow[ columnInfo.fullId ][ columnInfo.localId ] += ( undefined !== value ? this.reformatter.reformat( value, 'float' ) : 0 )
                            summary.byColumn[ columnInfo.fullId ] += ( undefined !== value ? this.reformatter.reformat( value, 'float' ) : 0 )
                            break
                        case 'count':
                            summary.byRow[ columnInfo.fullId ][ columnInfo.localId ]++
                            summary.byColumn[ columnInfo.fullId ]++
                            break
                        case 'countdefined':
                            if( undefined !== value && false !== value )
                            {
                                summary.byRow[ columnInfo.fullId ][ columnInfo.localId ]++
                                summary.byColumn[ columnInfo.fullId ]++
                            }
                            break
                    }

                    distinct.byRow[ columnInfo.fullId ][ columnInfo.localId ][ this.f.falseToUndef( value ) ] = !this.f.falseOrUndefined( distinct.byRow[ columnInfo.fullId ][ columnInfo.localId ][ value ] ) ? distinct.byRow[ columnInfo.fullId ][ columnInfo.localId ][ value ] + 1 : 1
                    distinct.byRow[ columnInfo.fullId ][ columnInfo.localId ][ '__total' ] = !this.f.falseOrUndefined( distinct.byRow[ columnInfo.fullId ][ columnInfo.localId ][ '__total' ] ) ? distinct.byRow[ columnInfo.fullId ][ columnInfo.localId ][ '__total' ] + 1 : 1
                    distinct.byColumn[ columnInfo.fullId ][ this.f.falseToUndef( value ) ] = distinct.byColumn[ columnInfo.fullId ][ this.f.falseToUndef( value ) ] !== undefined ? distinct.byColumn[ columnInfo.fullId ][ this.f.falseToUndef( value ) ] + 1 : 1
                    distinct.byColumn[ columnInfo.fullId ][ '__total' ] = distinct.byColumn[ columnInfo.fullId ][ '__total' ] !== undefined ? distinct.byColumn[ columnInfo.fullId ][ '__total' ] + 1 : 1

                    averages.byColumn[ columnInfo.fullId ] = summary.byColumn[ columnInfo.fullId ] / counts.byColumn[ columnInfo.fullId ]

                }

            }

            this.getObjectRespectingTimestamp( 'student', list.timestamp )
                .then( students =>
                {

                    let studentsSet = false
                    for( let c in columns )
                    {

                        let col    = columns[ c ],
                            fullId = col.columnId + '___' + ( parseInt( c ) + 1 )

                        if( col.type === 'studentData' )
                        {
                            if( !studentsSet )
                            {
                                if( 'all' !== list.columns[ 0 ].filterBy )
                                {
                                    let newStudents = []
                                    for( let s in students )
                                    {
                                        if( students[ s ].classId === list.columns[ 0 ].filterBy )
                                        {
                                            newStudents.push( students[ s ] )
                                        }
                                    }
                                    students = newStudents
                                }
                                studentsSet = true
                            }

                            for( let s in students )
                            {
                                if( undefined !== students[ s ][ col.caption ]
                                    && students[ s ][ col.caption ].trim() !== ''
                                    && students[ s ][ col.caption ].trim() !== '<br/>'
                                    && students[ s ][ col.caption ].trim() !== '&nbsp;' )
                                {
                                    if( undefined === summary.byColumn[ fullId ] )
                                    {
                                        summary.byColumn[ fullId ] = 0
                                        counts.byColumn[ fullId ] = 0
                                    }
                                    if( undefined !== this.filterId )
                                    {
                                        list.values[ this.filterId + '___' + col.columnId + '___' + ( parseInt( c ) + 1 ) ] = this.filterId + '-' + col.columnId
                                    }
                                    else
                                    {
                                        if( undefined === list.values )
                                        {
                                            list.values = {}
                                        }
                                        list.values[ students[ s ].localId + '___' + col.columnId + '___' + ( parseInt( c ) + 1 ) ] = students[ s ].localId + '-' + col.columnId
                                    }
                                    summary.byColumn[ fullId ]++
                                    counts.byColumn[ fullId ]++
                                }
                            }
                        }

                    }

                    let hasData = {}
                    for( let s in students )
                    {
                        let student = students[ s ]
//                        hasData[ student.localId ] = false
                        for( let c in summary.byRow )
                        {
                            let sum = summary.byRow[ c ]
                            if( undefined !== sum[ student.localId ] )
                            {
                                hasData[ student.localId ] = true
                            }
                        }
                    }

                    students = null

                    return resolve( {
                        hasData : hasData,
                        counts  : counts,
                        summary : summary,
                        averages: averages,
                        distinct: distinct
                    } )

                } )

        } )

    }

    addTotals( columns, summary, totals )
    {

        if( undefined === totals.grandTotals )
        {
            totals.grandTotals = {}
            totals.averages = {}
            totals.counts = {}
            totals.rowCounts = {}
            totals.distinct = {}
        }

        let counted = []

        for( let c in columns )
        {

            let column   = columns[ c ],
                columnId = column.columnId + '___' + ( parseInt( c ) + 1 ),
                value    = summary.summary.byColumn[ columnId ]

            if( undefined === totals[ columnId ] )
            {
                totals[ columnId ] = { byColumn: 0, byRow: {} }
            }

            switch( this.getTotalsType( column.type ) )
            {
                case 'addvalue':
                    totals[ columnId ].byColumn += this.reformatter.reformat( value, 'float' )
                    break
                case 'count':
                    totals[ columnId ].byColumn++
                    break
            }

            for( let key in summary.summary.byRow[ columnId ] )
            {

                let colValue = summary.summary.byRow[ columnId ][ key ]
                if( undefined === totals[ columnId ].byRow[ key ] )
                {
                    totals[ columnId ].byRow[ key ] = 0
                }

                if( undefined === totals.grandTotals[ key ] )
                {
                    totals.grandTotals[ key ] = 0
                }

                if( undefined === totals.counts[ key ] )
                {
                    totals.counts[ key ] = {}
                    totals.averages[ key ] = {}
                    totals.rowCounts[ key ] = 0
                }

                switch( this.getTotalsType( column.type ) )
                {
                    case 'addvalue':
                        totals[ columnId ].byRow[ key ] += this.reformatter.reformat( colValue, 'float' )
                        totals.grandTotals[ key ] += this.reformatter.reformat( colValue, 'float' )
                        break
                    case 'count':
                        totals[ columnId ].byRow[ key ]++
                        totals.grandTotals[ key ]++
                        break
                    case 'ignore':
                        break
                }

                totals[ columnId ].byRow[ key ] = this.reformatter.reformat( totals[ columnId ].byRow[ key ], 'float' )
                totals.grandTotals[ key ] = this.reformatter.reformat( totals.grandTotals[ key ], 'float' )

                if( -1 === counted.indexOf( key ) )
                {
                    totals.rowCounts[ key ] = undefined !== totals.rowCounts[ key ] ? totals.rowCounts[ key ] + 1 : 1
                    counted.push( key )
                }

                totals.counts[ key ][ columnId ] = undefined !== totals.counts[ key ][ columnId ] ? totals.counts[ key ][ columnId ] + 1 : 1
                totals.averages[ key ][ columnId ] = totals[ columnId ].byRow[ key ] / totals.counts[ key ][ columnId ]

            }

        }

        for( let columnId in summary.distinct.byRow )
        {

            if( undefined === totals.distinct[ columnId ] )
            {
                totals.distinct[ columnId ] = {}
            }

            for( let key in summary.distinct.byRow[ columnId ] )
            {

                if( undefined === totals.distinct[ columnId ][ key ] )
                {
                    totals.distinct[ columnId ][ key ] = {}
                }

                let set = summary.distinct.byRow[ columnId ][ key ]
                for( let v in set )
                {
                    totals.distinct[ columnId ][ key ][ v ] = undefined !== totals.distinct[ columnId ][ key ][ v ] ? totals.distinct[ columnId ][ key ][ v ] + set[ v ] : set[ v ]
                }

            }
        }

    }

    getHeadTypes( columns )
    {

        let headTypes = {}

        for( let c in columns )
        {

            let type     = 'totals',
                calcType = this.getCalcType( columns[ c ].type )

            if( null !== calcType )
            {
                switch( calcType )
                {
                    case 'distinct':
                        type = 'distincts'
                        break
                    case 'addvalue':
                        type = 'totals'
                        break
                    case 'count':
                        type = 'counts'
                        break
                    default:
                        type = calcType
                        break

                }
            }

            headTypes[ columns[ c ].columnId ] = type

        }

        return headTypes

    }

    getMarkers( list, filterId )
    {

        for( let key in list.values )
        {
            if( -1 < key.indexOf( filterId ) )
            {
                return ''
            }
        }

        return 'empty'

    }

    resultResolver( lists, columns, filterId, totals, result )
    {
        return new Promise( resolve =>
        {

            result = undefined === result ? [] : result

            if( 0 < lists.length )
            {

                let list = lists.shift()
                this.prepareSummary( list, columns )
                    .then( summary =>
                    {

                        let timestamp = list.timestamp

                        result.push( {
                            timestamp: timestamp,
                            columns  : columns,
                            summary  : summary,
                            listItem : list,
                            marker   : filterId !== undefined ? this.getMarkers( list, filterId ) : ''
                        } )

                        this.addTotals( columns, summary, totals )

                        return resolve( this.resultResolver( lists, columns, filterId, totals, result ) )

                    } )
            }
            else
            {
                return resolve( result )
            }

        } )
    }

    getSummary( lists, noAverages, filterList )
    {

        return new Promise( resolve =>
        {

            let filterId = this.getState( 'detailViewFor' )

            if( undefined !== filterId )
            {
                let test = filterId.split( ':' )
                if( 'student' === test[ 0 ] )
                {
                    filterId = test[ 1 ]
                }
                else
                {
                    filterId = undefined
                }
            }

            this.filterId = filterId
            this.filterList = filterList || false

            let totals           = {},
                fieldset         = this.getFieldDefinition( lists, 'summary' ),
                columns          = this.getColumns( lists[ 0 ], fieldset, noAverages ),
                displayComponent = this.getSummaryDisplay( lists ),
                todo             = []

            for( let l in lists )
            {

                todo.push( lists[ l ] )

            }

            this.resultResolver( todo, columns, filterId, totals )
                .then( result =>
                {

                    return resolve( {
                        filterId         : filterId,
                        totals           : totals,
                        result           : result,
                        headTypes        : this.getHeadTypes( columns ),
                        displayComponent : displayComponent,
                        possibleRowCounts: this.getPossibleRowCount( lists )
                    } )

                } )

        } )

    }

    getListSummary( lists )
    {

        return new Promise( resolve =>
        {

            let totals           = {},
                fieldset         = this.getFieldDefinition( lists, 'summary' ),
                columns          = this.getColumns( lists[ 0 ], fieldset ),
                displayComponent = this.getSummaryDisplay( lists ),
                todo             = []

            for( let l in lists )
            {

                todo.push( lists[ l ] )

            }

            this.resultResolver( todo, columns, undefined, totals )
                .then( result =>
                {

                    return resolve( {
                        totals           : totals,
                        result           : result,
                        headTypes        : this.getHeadTypes( columns ),
                        displayComponent : displayComponent,
                        possibleRowCounts: this.getPossibleRowCount( lists )
                    } )

                } )

        } )

    }

    hasCalculatedColumn( setup, calculatedColumns )
    {

        let cols = setup.values
        for( let c in cols )
        {
            for( let cc in calculatedColumns )
            {
                if( calculatedColumns[ cc ].label === cols[ c ] )
                {
                    return true
                }
            }
        }

        return false

    }

    findValue( label, id, valueSet )
    {

        let key = id + '___' + this.sanitizers.cleanId( label )

        for( let v in valueSet )
        {
            if( -1 < v.indexOf( key ) )
            {
                return valueSet[ v ]
            }
        }

        return 0

    }

    valueOrNull( calcResults, cleanId, id )
    {
        if( this.f.isset( calcResults[ cleanId ] )
            && this.f.isset( calcResults[ cleanId ][ id ] )
            && this.f.isset( calcResults[ cleanId ][ id ].raw ) )
        {
            return calcResults[ cleanId ][ id ].raw
        }
        return null
    }

    processCalculation( method, id, calcColumn, valueSet, calcResults )
    {

        let nameA    = calcColumn.values[ 0 ],
            nameB    = calcColumn.values[ 1 ],
            cleanIdA = this.sanitizers.cleanId( nameA ),
            cleanIdB = this.sanitizers.cleanId( nameB ),
            valueA   = 0,
            valueB   = 0

        valueA = undefined !== calcResults[ cleanIdA ] ? this.valueOrNull( calcResults, cleanIdA, id ) : this.findValue( nameA, id, valueSet )
        valueB = undefined !== calcResults[ cleanIdB ] ? this.valueOrNull( calcResults, cleanIdB, id ) : this.findValue( nameB, id, valueSet )

        switch( method )
        {
            case 'percentage':
                return parseFloat( valueA * 100 / valueB )
            case 'factor':
                return parseFloat( 0 !== valueB ? valueA / valueB : 0 )
            case 'subtract':
                return parseFloat( valueA - valueB )
        }
    }

    isFieldRequested( calcColumn, fieldId )
    {
        for( let c in calcColumn.values )
        {
            if( this.sanitizers.cleanId( calcColumn.values[ c ] ) === fieldId )
            {
                return true
            }
        }
        return false
    }

    calculateField( id, fieldId, calcColumn, set, result, valueSet, calcResults )
    {

        let cellValue = this.f.isset( set ) ? this.reformatter.float( set ) : 0,
            hasValue  = this.f.isset( set ),
            calcResult

        if( isNaN( cellValue ) )
        {
            cellValue = 0
        }

        if( !hasValue )
        {
            if( this.f.isset( calcResults[ fieldId ] )
                && this.f.isset( calcResults[ fieldId ][ id ] )
                && this.isFieldRequested( calcColumn, fieldId ) )
            {

                hasValue = true
                cellValue = calcResults[ fieldId ][ id ].raw

            }
        }

        if( undefined === result[ id ] )
        {
            result[ id ] = {
                raw    : 0,
                display: 0
            }
        }

        switch( calcColumn.calcType )
        {
            case 'sum':
            case 'average':
                result[ id ].raw += hasValue ? cellValue : 0
                result[ id ].display = result[ id ].raw
                break
            case 'count':
                result[ id ].raw += hasValue ? 1 : 0
                result[ id ].display = result[ id ].raw
                break
            case 'percentage':
                calcResult = this.processCalculation( 'percentage', id, calcColumn, valueSet, calcResults )
                result[ id ] = {
                    raw    : calcResult,
                    display: this.cleanFormat( calcResult ) + '%'
                }
                break
            case 'factor':
                calcResult = this.processCalculation( 'factor', id, calcColumn, valueSet, calcResults )
                result[ id ] = {
                    raw    : calcResult,
                    display: this.cleanFormat( calcResult )
                }
                break
            case 'subtract':
                calcResult = this.processCalculation( 'subtract', id, calcColumn, valueSet, calcResults )
                result[ id ] = {
                    raw    : calcResult,
                    display: this.cleanFormat( calcResult )
                }
                break
        }

    }

    calculateColumn( set, colId, columns, calcColumn, calcResults )
    {

        let result = {},
            fields = calcColumn.values,
            done   = false

        for( let s in set )
        {

            let temp    = s.split( /___/g ),
                id      = temp.shift(),
                fieldId = temp.shift()

            for( let f in fields )
            {
                let calcFieldId = this.sanitizers.cleanId( fields[ f ] )
                if( calcFieldId === fieldId )
                {

                    done = true
                    this.calculateField( id, fieldId, calcColumn, set[ s ], result, set, calcResults )

                }
            }

        }

        if( !done )
        {
            let allCalc = true
            for( let cc in calcColumn.values )
            {
                let key = this.sanitizers.cleanId( calcColumn.values[ cc ] )
                if( undefined === calcResults[ key ] )
                {
                    allCalc = false
                }
            }
            if( allCalc )
            {
                for( let cr in calcResults )
                {
                    for( let id in calcResults[ cr ] )
                    {
                        this.calculateField( id, cr, calcColumn, undefined, result, set, calcResults )
                    }
                }
            }
        }

        if( calcColumn.calcType === 'average' )
        {

            for( let id in result )
            {
                result[ id ] = {
                    raw    : result[ id ].raw / fields.length,
                    display: this.cleanFormat( result[ id ].raw / fields.length )
                }
            }

        }

        return result

    }

    isResolvable( results, colId, col, columns )
    {

        let resolvable = []

        for( let c in columns )
        {
            if( 'fixed' !== columns[ c ].type )
            {
                resolvable.push( this.sanitizers.cleanId( columns[ c ].caption ) )
            }
        }

        for( let r in results )
        {
            resolvable.push( r )
        }

        for( let v in col.values )
        {
            if( 0 > resolvable.indexOf( this.sanitizers.cleanId( col.values[ v ] ) ) )
            {
                return false
            }
        }

        return true

    }

    resolveCalculatedColumns( set, list )
    {

        let results           = {},
            columns           = list.columns,
            calculatedColumns = undefined !== list.calculatedColumns ? JSON.parse( JSON.stringify( list.calculatedColumns ) ) : [],
            needsResolve      = [],
            maxRecursion      = ( list.columns.length + calculatedColumns.length ) * 2,
            step              = 0

        for( let c in calculatedColumns )
        {
            let col = calculatedColumns[ c ]
            if( this.hasCalculatedColumn( col, list.calculatedColumns ) )
            {
                needsResolve.push( this.sanitizers.cleanId( col.label ) )
            }
        }

        while( 0 < calculatedColumns.length
               && step < maxRecursion )
        {

            step++

            let col          = calculatedColumns.shift(),
                colId        = this.sanitizers.cleanId( col.label ),
                resultLookup = 0 <= needsResolve.indexOf( colId ) && this.isResolvable( results, colId, col, columns )

            if( 0 > needsResolve.indexOf( colId )
                || this.isResolvable( results, colId, col, columns ) )
            {
                let result = this.calculateColumn( set, colId, columns, col, results, resultLookup )
                results[ colId ] = result
            }
            else
            {
                calculatedColumns.push( col )
            }

        }

        columns = null
        calculatedColumns = null
        needsResolve = null
        maxRecursion = null
        step = null

        return results

    }

    cleanFormat( number )
    {

        let test = this.reformatter.localizedFloat( number, 2 )

        if( -1 < ( '' + test ).indexOf( ',' ) )
        {
            let temp = test.split( /,/g )
            {
                if( 0 === parseInt( temp[ 1 ] ) )
                {
                    return number
                }
            }
            return test
        }

        return isNaN( number ) ? '-' : number

    }

    summarizeByStudents( calculationResult, setup )
    {

        if( !Array.isArray( setup ) )
        {
            return
        }

        let byStudent = {},
            count     = {},
            calcTypes = {}

        for( let s in setup )
        {
            calcTypes[ this.sanitizers.cleanId( setup[ s ].label ) ] = setup[ s ].calcType
        }

        for( let localId in calculationResult )
        {
            for( let fieldId in calculationResult[ localId ] )
            {
                if( this.f.isset( calculationResult[ localId ][ fieldId ].listResult )
                    && this.f.isset( calculationResult[ localId ][ fieldId ].listResult[ fieldId ] ) )
                {
                    let todo = calculationResult[ localId ][ fieldId ].listResult[ fieldId ]
                    for( let studentId in todo )
                    {

                        if( undefined === byStudent[ studentId ] )
                        {
                            byStudent[ studentId ] = {}
                            count[ studentId ] = {}
                        }
                        if( undefined === byStudent[ studentId ][ fieldId ] )
                        {
                            byStudent[ studentId ][ fieldId ] = { raw: 0, display: 0 }
                            count[ studentId ][ fieldId ] = 0
                        }
                        byStudent[ studentId ][ fieldId ].raw += todo[ studentId ].raw
                        count[ studentId ][ fieldId ]++

                    }
                }

            }
        }

        /* TODO: Make configurable header calc type */

        for( let studentId in byStudent )
        {
            for( let fieldId in byStudent[ studentId ] )
            {
                switch( calcTypes[ fieldId ] )
                {
                    case 'percentage':
                        byStudent[ studentId ][ fieldId ].raw = byStudent[ studentId ][ fieldId ].raw / count[ studentId ][ fieldId ]
                        byStudent[ studentId ][ fieldId ].display = this.cleanFormat( byStudent[ studentId ][ fieldId ].raw ) + '%'
                        break
                    case 'average':
                        byStudent[ studentId ][ fieldId ].raw = byStudent[ studentId ][ fieldId ].raw / count[ studentId ][ fieldId ]
                        byStudent[ studentId ][ fieldId ].display = this.cleanFormat( byStudent[ studentId ][ fieldId ].raw )
                        break
                    default:
                        byStudent[ studentId ][ fieldId ].raw = byStudent[ studentId ][ fieldId ].raw / count[ studentId ][ fieldId ]
                        byStudent[ studentId ][ fieldId ].display = this.cleanFormat( byStudent[ studentId ][ fieldId ].raw )
                        break
                }
            }
        }

        calculationResult.byStudent = byStudent

    }

    resolveCalculatedColumnsSummary( set, list )
    {

        let results           = {},
            listResult        = this.resolveCalculatedColumns( set, list ),
            calculatedColumns = undefined !== list.calculatedColumns ? JSON.parse( JSON.stringify( list.calculatedColumns ) ) : []

        for( let c in calculatedColumns )
        {

            let id     = this.sanitizers.cleanId( calculatedColumns[ c ].label ),
                result = 0,
                count  = 0

            results[ id ] = 0

            for( let i in listResult[ id ] )
            {
                count++
                result += listResult[ id ][ i ].raw
            }

            switch( calculatedColumns[ c ].calcType )
            {
                case 'subtract':
                case 'average':
                case 'percentage':
                case 'factor':
                    result = result / count
                    break
            }

            results[ id ] = {
                raw       : result,
                display   : this.cleanFormat( result ) + ( calculatedColumns[ c ].calcType === 'percentage' ? '%' : '' ),
                listResult: listResult
            }

        }

        return results

    }

    distinctsSkeleton( column )
    {
        switch( column.type )
        {
            case 'threewaytoggle':
                return {
                    0: 0,
                    1: 0,
                    2: 0
                }
            default:
                return null
        }
    }

    getStudentId( key )
    {
        let temp = key.split( /___/g )
        if( 1 <= temp.length )
        {
            return temp[ 0 ]
        }
        return null
    }

    createStudentSkeleton()
    {
        return {
            hasData         : false,
            counts          : {},
            sums            : {},
            distincts       : {},
            defined         : {},
            averages        : {},
            averagesOfTotals: {},
            display         : {},
            __filled        : 0,
            __total         : 0
        }
    }

    getDisplayValue( result, colId, type )
    {
        switch( this.getCalcType( type ) )
        {
            case 'addvalue':
                return result.sums[ colId ]
            case 'count':
                return result.counts[ colId ]
            case 'countdefined':
                return result.defined[ colId ]
            case 'average':
                return result.averages[ colId ]
            case 'distinct':
            case 'distincts':
                break
            default:
                this.logger.clog( 'TableCalculation::calculateListSummary', 'calcType not precise:', this.getCalcType( type ) )
                break
        }
    }

    _summarizeByStudent( byStudent, debug )
    {

        let _total = {}

        for( let l in byStudent )
        {

            for( let s in byStudent[ l ] )
            {

                if( undefined === _total[ s ] )
                {
                    _total[ s ] = this.createStudentSkeleton()
                }

                for( let c in byStudent[ l ][ s ].counts )
                {
                    _total[ s ].counts[ c ] = _total[ s ].counts[ c ] || 0
                    _total[ s ].counts[ c ] += byStudent[ l ][ s ].counts[ c ]
                }
                for( let c in byStudent[ l ][ s ].sums )
                {
                    _total[ s ].sums[ c ] = _total[ s ].sums[ c ] || 0
                    _total[ s ].sums[ c ] += byStudent[ l ][ s ].sums[ c ]
                }
                for( let c in byStudent[ l ][ s ].defined )
                {
                    if( byStudent[ l ][ s ].defined[ c ] === 1 )
                    {
                        _total[ s ].defined[ c ] = true
                    }
                }
                if( byStudent[ l ][ s ].hasData === true )
                {
                    _total[ s ].__filled++
                    _total[ s ].hasData = true
                }

                _total[ s ].__total = Object.keys( byStudent ).length

            }
        }

        for( let t in _total )
        {
            for( let c in _total[ t ].sums )
            {

                let average        = _total[ t ].sums[ c ] / _total[ t ].counts[ c ],
                    averageOfTotal = _total[ t ].sums[ c ] / _total[ t ].__filled

                _total[ t ].averages[ c ] = average
                _total[ t ].averagesOfTotals[ c ] = averageOfTotal

                _total[ t ].display[ c ] = this.reformatter.localizedFloat( _total[ t ].averages[ c ], 2 )

            }
        }

        byStudent.__total = _total

    }

    calculateListSummary( lists )
    {

        let debug = false

        if( !Array.isArray( lists )
            || 0 === lists.length )
        {
            return null
        }

        let results    = {
                calculation: {},
                byStudent  : {},
                rowMonths  : [],
                listInMonth: {},
                countMonths: {},
                hasData    : {},
                multiMonths: false,
                headTypes  : false
            },
            columns    = this.getColumns( lists[ 0 ], this.getFieldDefinition( lists, 'summary' ), undefined, true ),
            _columns   = lists[ 0 ].columns,
            monthIndex = -1

        results.headTypes = this.getHeadTypes( columns )

        for( let l in lists )
        {

            if( false === lists[ l ] )
            {
                continue
            }

            let studentList   = this.getStudentIdsForList( lists[ l ] ),
                result        = {
                    studentCount: studentList.length,
                    counts      : {},
                    sums        : {},
                    distincts   : {},
                    defined     : {},
                    averages    : {},
                    display     : {},
                },
                byStudent     = {},
                shortSortable = this.friendlyTimestamp.shortSortable( lists[ l ].timestamp )

            if( !results.rowMonths.find( o => o.sortable === shortSortable ) )
            {
                monthIndex++
                results.rowMonths.push( {
                    sortable: shortSortable,
                    friendly: this.friendlyTimestamp.friendlySortable( lists[ l ].timestamp )
                } )
            }

            results.listInMonth[ lists[ l ].localId ] = monthIndex
            results.countMonths[ shortSortable ] = undefined !== results.countMonths[ shortSortable ]
                                                   ? results.countMonths[ shortSortable ] + 1 : 1

            lists[ l ]._tcShortSortable = shortSortable

            for( let c in columns )
            {

                if( 0 <= parseInt( c ) )
                {
                    let colId   = columns[ c ].columnId,
                        valueId = columns[ c ].valueId //this.sanitizers.cleanId( columns[ c ].caption ) + '___' + ( parseInt( c ) )

                    if( undefined === result.counts[ colId ] )
                    {
                        result.counts[ colId ] = 0
                        result.sums[ colId ] = 0
                        result.distincts[ colId ] = this.distinctsSkeleton( columns[ c ] )
                        result.defined[ colId ] = 0
                        result.averages[ colId ] = 0
                        result.display[ colId ] = 'n/a'
                    }

                    for( let v in lists[ l ].values )
                    {

                        let studentId = this.getStudentId( v )
                        if( undefined === byStudent[ studentId ] )
                        {
                            byStudent[ studentId ] = this.createStudentSkeleton()
                        }

                        if( undefined === byStudent[ studentId ].counts[ colId ] )
                        {
                            byStudent[ studentId ].sums[ colId ] = 0
                            byStudent[ studentId ].counts[ colId ] = 0
                            byStudent[ studentId ].distincts[ colId ] = this.distinctsSkeleton( columns[ c ] )
                            byStudent[ studentId ].defined[ colId ] = 0
                            byStudent[ studentId ].averages[ colId ] = 0
                            byStudent[ studentId ].display[ colId ] = 'n/a'
                        }

                        if( -1 < v.indexOf( valueId ) )
                        {
                            byStudent[ studentId ].hasData = true
                            byStudent[ studentId ].counts[ colId ]++
                            byStudent[ studentId ].defined[ colId ] += this.f.valid( lists[ l ].values[ v ] ) ? 1 : 0
                            byStudent[ studentId ].sums[ colId ] += this.reformatter.isFloat( lists[ l ].values[ v ] ) ? this.reformatter.float( lists[ l ].values[ v ] ) : 1
                            byStudent[ studentId ].__total += this.reformatter.isFloat( lists[ l ].values[ v ] ) ? this.reformatter.float( lists[ l ].values[ v ] ) : 0

                            results.hasData[ shortSortable ] = true
                            result.counts[ colId ]++
                            result.defined[ colId ] += this.f.valid( lists[ l ].values[ v ] ) ? 1 : 0
                            result.sums[ colId ] += this.reformatter.isFloat( lists[ l ].values[ v ] ) ? this.reformatter.float( lists[ l ].values[ v ] ) : 1
                            if( null !== result.distincts[ colId ] )
                            {
                                if( undefined !== ( result.distincts[ colId ][ lists[ l ].values[ v ] ] ) )
                                {
                                    result.distincts[ colId ][ lists[ l ].values[ v ] ]++
                                    byStudent[ studentId ].distincts[ colId ][ lists[ l ].values[ v ] ]++
                                }
                            }
                        }

                        byStudent[ studentId ].display[ colId ] = this.getDisplayValue( byStudent[ studentId ], colId, columns[ c ].type )

                    }

                    if( 0 < result.counts[ colId ] )
                    {
                        result.averages[ colId ] = this.reformatter.reformat( result.sums[ colId ] / result.counts[ colId ], 'float' )
                    }

                    let display = this.getDisplayValue( result, colId, columns[ c ].type )
                    if( undefined !== display )
                    {
                        result.display[ colId ] = display
                    }

                }

            }

            results.calculation[ lists[ l ].localId ] = result
            results.byStudent[ lists[ l ].localId ] = byStudent

        }
        this._summarizeByStudent( results.byStudent, debug )
        results.multiMonths = ( -1 !== parseInt( this.settings.getSetting( 'listGroupLimit' ) )
                                && lists.length > parseInt( this.settings.getSetting( 'listGroupLimit' ) )
                                && results.rowMonths.length > 1 )

        return results;

    }

}