export default class DeepDependencyWalkerCompetence
{

    constructor( parent )
    {
        if( !DeepDependencyWalkerCompetence.instance )
        {

            this.core = parent.core
            this.logger = parent.logger
            this.store = parent.store
            this.f = parent.f

            this.competenceCategoryTree = this.core.getCompetenceCategoryTree()

            this.eventManager = parent.eventManager
            this.logSign = 'Share::helpers::deepDependecyWalkerCompetence [SHR-DDWCOMP]:'

            this.baseClassHelper = parent.baseClassHelper
            if( undefined === this.competenceCategoryTree )
            {
                this.eventManager.append( 'on-competence-category-tree-ready', ( tree ) =>
                {
                    this.competenceCategoryTree = tree
                } )
            }

            this.localResolved = []

            this.logger.clog( this.logSign + 'constructor', 'initializing deepDependencyWalker for competence objects...' )
            DeepDependencyWalkerCompetence.instance = this

        }

        return DeepDependencyWalkerCompetence.instance

    }

    hasSubs( element )
    {
        return Array.isArray( element.subs ) && 0 < element.subs.length
    }

    isResolved( localId, resolved )
    {
        return undefined !== resolved.find( o => o.localId === localId )
            || undefined !== this.localResolved.find( o => o.localId === localId )
    }

    addDependency( localId, dependencies, resolved )
    {
        if( !this.isResolved( localId, resolved ) )
        {
            let element = this.baseClassHelper.getObjectById( localId )
            if( undefined !== element )
            {
                this.localResolved.push( element )
                dependencies.push( element )
            }
        }
    }

    resolve( localId, resolved )
    {
        let root         = this.competenceCategoryTree.tree ? this.competenceCategoryTree.tree.byRoot[ localId ] : undefined,
            dependencies = []

        if( undefined !== root )
        {
            this.addDependency( root.localId, dependencies, resolved )
            if( this.hasSubs( root ) )
            {
                for( let s in root.subs )
                {
                    this.addDependency( root.subs[ s ].localId, dependencies, resolved )
                    if( this.hasSubs( root.subs[ s ] ) )
                    {
                        for( let ss in root.subs[ s ].subs )
                        {
                            this.addDependency( root.subs[ s ].subs[ ss ].localId, dependencies, resolved )
                            if( this.hasSubs( root.subs[ s ].subs[ ss ] ) )
                            {
                                for( let sss in root.subs[ s ].subs[ ss ].subs )
                                {
                                    this.addDependency( root.subs[ s ].subs[ ss ].subs[ sss ].localId, dependencies, resolved )
                                }
                            }
                        }
                    }
                }
            }
        }

        this.localResolved = []
        return dependencies

    }

}