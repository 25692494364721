<!--eslint-disable-->
<template>
    <div v-if="ready"
         :key="'listSummary-'+ownId">
        <template
            v-if="'student' === filterBy && listData.length > 1 && true === $core.settings().getSetting( 'studentFileShowOnlyFilled' )">
            <button type="button" :class="'auto right '+(showAll ? 'cancel' : 'submit')" @click="toggleShowAll()">
                {{ showAll ? 'andere ausblenden' : 'alle einblenden' }}
            </button>
        </template>
        <table class="list" :id="'printable-'+item.referenceKey">
            <thead>
            <tr class="list-header">
                <th>&nbsp;</th>
                <TableHeadRotated v-for="( col, index ) in item.columns.slice( 1, item.columns.length )"
                                  :keyV="index"
                                  :score="col.score"
                                  :type="col.type"
                                  :typeForce="headlineSetupType( col.id )"
                                  :hideSub="( filter && 'student' === filter.type ) || 'threewaytoggle' === col.type"
                                  :caption="(col.type !== 'studentData' ? col.caption : $core.getTranslation().translate( 'caption_'+col.caption ))"
                                  @switchSetup="handleSwitchSetup( col.id, $event )"/>
                <CalculationTableHeaders :list="item"
                                         :viewKey="elementKey"
                                         @count="expandHeaders = $event"/>
            </tr>
            </thead>
            <tbody>
            <template v-for="( row, index ) in viewItem.lists"
                      :key="'tr-'+row.timestamp+'-'+row.elementKey+'-'+( inListEditor && lum.hooks[ row.localId ] ? 'OPEN' : '' )">
                <ListElementMonthSeparator
                    v-if="!$core.getState( 'in-element-zoom' )
                          && item._tc.summary.multiMonths === true
                          && true !== skipSeparators
                          && true === row._showSeparator
                          && 1 < item.lists.length
                          /*&& ( true === stats[rowMonths[index].sortable].hasData || showAll )*/
                          /*&& ( undefined === rowMonths[(index-1)] || rowMonths[index].sortable !== rowMonths[(index-1)].sortable )*/"
                    :initialState="-1 < openMonths.indexOf( row._tcShortSortable )"
                    :shortCaption="row._tcShortSortable"
                    :caption="$core.getFriendlyTimestamp().friendlySortable( row.timestamp )"
                    :colspan="( item.columns.length + expandHeaders + 1 )"
                    :count="item._tc.summary.countMonths[ row._tcShortSortable ]"
                    @toggle="handleOpenMonth( row._tcShortSortable )"/>
                <template v-if="filter
                                && 'student' === filter.type
                                && item._tc.summary.byStudent[ row.localId ]
                                && item._tc.summary.byStudent[ row.localId ][ filter.localId ]">
                    <tr v-if="( !item._tc.summary.multiMonths || -1 < openMonths.indexOf( row._tcShortSortable ) || true === skipSeparators )"
                        @click="handleRowClick( row )"
                        :id="'tr-'+row.localId+'-'+row.timestamp">
                        <td>{{ $core.getFriendlyTimestamp().formattedDate( row.timestamp ) }}
                            <span v-if="openingElement === row.localId">*</span>
                            <ListOpenInEditorDisplay :display="inListEditor && openInEditor[ row.localId ]"/>
                            <div v-if="$core.settings().getSetting( 'debugInfo' )" class="debug-info">
                                #{{ row.localId }}, REMOTE: {{ row.remoteId }}, REF:
                                {{ row.referenceKey }}, ARCH: {{ row.archiveKey }}
                            </div>
                        </td>
                        <template v-for="( col, idx ) in item.columns.slice( 1, item.columns.length )"
                                  :key="'td-'+row.timestamp+'-'+col.id">
                            <td v-if="'hide' !== item._tc.summary.headTypes[col.id]"
                                v-bind:class="'td-summary td-'+col.type">
                                <template v-if="'competenceSelector' === col.type">
                                    <ListElement :type="col.type"
                                                 :row="filter.localId+'-'+col.id.split( /_/g ).pop()"
                                                 :value="undefined !== row.values ? row.values[ filter.localId+'___'+col.id.split( /_/g ).pop()+'___'+( idx + 1 ) ] : ''"
                                                 :referenceObjectId="row.localId"/>
                                </template>
                                <template v-else>
                                    <template
                                        v-if="row.values[ filter.localId+'___'+idx+'_'+col.id.split( /_/g ).pop()+'___'+( idx + 1 ) ]">
                                        <ListElement :type="col.type"
                                                     :value="undefined !== row.values ? row.values[ filter.localId+'___'+idx+'_'+col.id.split( /_/g ).pop()+'___'+( idx + 1 ) ] : ''"/>
                                    </template>
                                    <template v-else>
                                        <ListElement :type="col.type"
                                                     :value="undefined !== row.values ? row.values[ filter.localId+'___'+col.id.split( /_/g ).pop()+'___'+( idx + 1 ) ] : ''"/>
                                    </template>

                                </template>
                            </td>
                        </template>
                        <CalculationTableFields :filterId
                                                :role="'summary'"
                                                :data="item._tc.columns"
                                                :localId="row.localId"
                                                :viewKey="item._tc.columns.calculationKey"
                                                :calculatedColumns="row.calculatedColumns"/>
                    </tr>
                </template>
                <template v-else-if="!filter || 'student' !== filter.type">
                    <tr v-if="( !item._tc.summary.multiMonths || -1 < openMonths.indexOf( row._tcShortSortable ) || true === skipSeparators )"
                        @click="handleRowClick( row )"
                        :id="'tr-'+row.localId+'-'+row.timestamp">
                        <td>{{ $core.getFriendlyTimestamp().formattedDate( row.timestamp ) }}
                            <span v-if="openingElement === row.localId">*</span>
                            <ListOpenInEditorDisplay :display="inListEditor && openInEditor[ row.localId ]"/>
                            <div v-if="$core.settings().getSetting( 'debugInfo' )" class="debug-info">
                                #{{ row.localId }}, REMOTE: {{ row.remoteId }}, REF:
                                {{ row.referenceKey }}, ARCH: {{ row.archiveKey }}
                            </div>
                        </td>
                        <template v-for="( col, idx ) in item.columns.slice( 1, item.columns.length )"
                                  :key="'td-tpl'+row.timestamp+'-'+col.id">
                            <td v-if="'hide' !== item._tc.summary.headTypes[col.id]"
                                v-bind:class="'td-summary td-'+col.type">
                                <template v-if="item.listType === 'test'">
                                    <template v-if="item._tc
                                                    && item._tc.summary
                                                    && item._tc.summary.calculation[ row.localId ]
                                                    && item._tc.summary.calculation[ row.localId ].averages">
                                        <template v-if="'testcomment' !== col.type">
                                            {{
                                                $core.getReformatter().reformat( item._tc.summary.calculation[ row.localId ].averages[ idx + '_' + $core.getSanitizers().cleanId( col.caption ) ], 'localizedFloat' )
                                            }}
                                        </template>
                                        <template v-else>
                                            {{
                                                $core.getReformatter().reformat( item._tc.summary.calculation[ row.localId ].counts[ idx + '_' + $core.getSanitizers().cleanId( col.caption ) ], 'int' )
                                            }}
                                        </template>
                                    </template>
                                    <template v-else>
                                        -
                                    </template>
                                </template>
                                <template v-else-if="headlineSetup( col.id, 'distinct', col.type )">
                                    <template v-if="undefined !== filterId">
                                        <ListElement :type="col.type"
                                                     :value="undefined !== row.listItem.values ? row.listItem.values[ filterId + '___' + col.id + '___' + ( 1 + idx ) ] : ''"/>
                                    </template>
                                    <template v-else>
                                        <template v-if="item._tc.summary.calculation[row.localId ]
                                                    && item._tc.summary.calculation[row.localId ].distincts">
                                            <ListElementDistincts
                                                :distinct="item._tc.summary.calculation[row.localId ].distincts[col.id]"
                                                :totalCount="item._tc.summary.calculation[row.localId ].studentCount"
                                                :type="col.type"/>
                                        </template>
                                    </template>
                                </template>
                                <template v-else-if="headlineSetup( col.id, 'totals' )">
                                    <template v-if="undefined !== filterId && undefined !== row.values">
                                        <ListElement :type="col.type"
                                                     :value="undefined !== row.values ? row.values[ filterId + '___' + col.id + '___' + ( 1 + idx ) ] : ''"/>
                                    </template>
                                    <template v-else>
                                        <template v-if="item._tc.summary.calculation[ row.localId ]">
                                            <span>
                                                {{
                                                    item._tc.summary.calculation[ row.localId ].sums[ col.id ]
                                                }}
                                            </span>
                                        </template>
                                    </template>
                                </template>
                                <template v-else-if="headlineSetup( col.id, 'average' )">
                                    <template v-if="undefined !== item._tc.summary.calculation[ row.localId ]
                                                && undefined !== item._tc.summary.calculation[ row.localId ].averages[col.id]">
                                        <span>
                                            {{
                                                item._tc.summary.calculation[ row.localId ].averages[ col.id ]
                                            }}
                                        </span>
                                    </template>
                                </template>
                                <template v-else-if="headlineSetup( col.id, 'counts' )">
                                    <template v-if="undefined !== item._tc.summary.calculation[ row.localId ]
                                                && undefined !== item._tc.summary.calculation[ row.localId ].averages[col.id]">
                                        <span>
                                            {{
                                                item._tc.summary.calculation[ row.localId ].counts[ col.id ]
                                            }}
                                        </span>
                                    </template>
                                </template>
                                <template v-else>
                                    <strong>Fehler</strong>: CalculationModel not defined
                                </template>
                            </td>
                        </template>
                        <CalculationTableFields :filterId
                                                :role="'summary'"
                                                :data="item._tc.columns"
                                                :localId="row.localId"
                                                :viewKey="item._tc.columns.calculationKey"
                                                :calculatedColumns="row.calculatedColumns"/>
                    </tr>
                </template>
            </template>
            </tbody>
        </table>
    </div>
</template>

<script>
/*eslint-disable*/
import ListElementDistincts        from '@/components/elements/viewItems/listViews/listElement-distincts/ListElementDistincts'
import ListElement                 from '@/components/elements/viewItems/listViews/ListElement'
import ListElementMonthSeparator
                                   from '@/components/elements/viewItems/listViews/listElements/listElement-monthSeparator'
import TableHeadRotated            from '@/components/elements/viewItems/listViews/tableElements/TableHeadRotated'
import ListPreparationHelper       from '@/components/elements/viewItems/listViews/helpers/ListPreparationHelper'
import CalculationTableHeaders     from "@/components/elements/viewItems/listViews/CalculationTableHeaders";
import CalculationTableFields      from "@/components/elements/viewItems/listViews/CalculationTableFields";
import MixinCachePreheater         from "@/mixins/MixinCachePreheater";
import MixinContainerUpdateTracker from "@/components/elements/viewItems/listViews/mixins/MixinContainerUpdateTracker";
import MixinStructureKey           from "@/components/elements/viewItems/listViews/mixins/MixinStructureKey";
import ListOpenInEditorDisplay     from "@/components/elements/viewItems/listViews/ListOpenInEditorDisplay";
import MixinComponentCleanup       from "@/mixins/MixinComponentCleanup.vue";

export default {

    name      : 'ListSummary',
    extends   : ListPreparationHelper,
    mixins    : [ MixinCachePreheater, MixinContainerUpdateTracker, MixinStructureKey, MixinComponentCleanup ],
    components: {
        ListOpenInEditorDisplay,
        CalculationTableFields,
        CalculationTableHeaders,
        TableHeadRotated,
        ListElementMonthSeparator,
        ListElement,
        ListElementDistincts
    },
    emits     : [ 'rowClick', 'dataUpdate' ],
    props     : {
        item          : { type: Object, required: true },
        elementKey    : { Type: String, required: false },
        changeKey     : { Type: String, required: false },
        listFilter    : { type: [ Object, Boolean ], required: true },
        skipSeparators: { Type: Boolean, required: false, default: false },
        inListEditor  : { type: Boolean, required: false, default: false },
        openingElement: { Type: String, required: false }
    },

    watch: {

        'lum.hooks': {
            immediate: true,
            deep     : true,
            handler()
            {
                this.checkHooks()
                this.prepare()
            }
        },

        'lum.unhookQueue': {
            immediate: true,
            deep     : true,
            handler()
            {
                this.checkHooks()
            }
        },

        elementKey: {
            immediate: true,
            handler( newValue, oldValue )
            {

                if( true !== this.$core.getState( 'listEditorOpen' )
                    && newValue !== oldValue
                    && this.ready )
                {
                    this.prepare()
                        .then( () =>
                        {
                            this.$emit( 'dataUpdate' )
                            this.calculationKey = this.$core.getUuid().generate()
                        } )
                }
                else if( false === this.registeredEvent
                         && true === this.$core.getState( 'listEditorOpen' )
                         && newValue !== oldValue
                         && this.ready )
                {
                    this.$core.getEventManager()
                        .append( 'on-listeditor-closed', () =>
                        {
                            this.prepare()
                                .then( () =>
                                {
                                    this.$emit( 'dataUpdate' )
                                    this.calculationKey = this.$core.getUuid().generate()
                                } )
                        } )
                    this.registeredEvent = true
                }
            }
        },

        listFilter: {
            immediate: true,
            deep     : true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && oldValue !== undefined
                    && this.ready )
                {

                    this.listFilterList = newValue
                    this.prepare()

                }
            }
        }
    },

    data()
    {
        return {
            lum              : null,
            viewItem         : false,
            registeredEvent  : false,
            ownId            : this.$core.getUuid().generate(),
            neededCaches     : [ 'class', 'group', 'yeargroup', 'student' ],
            calculationKey   : false,
            calculatedRows   : {},
            listData         : [],
            expandHeaders    : 0,
            totals           : false,
            ready            : false,
            headTypes        : false,
            filterBy         : undefined,
            filterId         : undefined,
            listFilterList   : false,
            showAll          : false,
            elementComponent : false,
            firstcolumn      : [],
            columns          : false,
            studentCount     : 0,
            totalScore       : 0,
            prepared         : false,
            rowMonths        : [],
            countMonths      : {},
            stats            : {},
            possibleRowCounts: {},
            openMonths       : [],
            multiMonths      : false,
            openInEditor     : {},
            filter           : false
        }
    },

    created()
    {
        this.lum = this.$core.getListUpdateManager()
        if( undefined !== this.$core.getState( 'detailViewFor' ) )
        {
            let temp = this.$core.getState( 'detailViewFor' ).split( /:/g )
            this.filter = {
                type   : temp[ 0 ],
                localId: temp[ 1 ]
            }
        }
        this.awaitNeededCaches()
            .then( () =>
            {
                this.prepare()
            } )
    },

    beforeUnmount()
    {
        this.lum = null
        this.$core.getEventManager()
            .remove( 'on-changekey-' + this.changeKey + '-resolve' )
    },

    methods: {

        reset( ready )
        {
            this.listData = false
            this.totals = false
            this.ready = ready
            this.filterId = undefined
            this.filterBy = undefined
            this.showAll = false
            this.elementComponent = false
            this.headTypes = false
            this.firstcolumn = []
            this.columns = false
            this.studentCount = 0
            this.totalScore = 0
            this.possibleRowCounts = {}
            this.rowMonths = []
            this.countMonths = {}
            this.stats = {}
            this.multiMonths = false
        },

        checkHooks()
        {
            if( this.lum )
            {
                for( let h in this.lum.hooks )
                {
                    if( !this.lum.unhookQueue[ h ] )
                    {
                        this.openInEditor[ h ] = true
                    }
                    else
                    {
                        delete this.openInEditor[ h ]
                    }
                }
            }
        },

        /*eslint-disable*/
        prepare()
        {

            return new Promise( resolve =>
            {

                this.totalScore = 0
                let sortRules = this.$core.f().deref( this.$core.settings().getSetting( 'sortingDirections' ) )
                this.$core.sort().multiSortObjects( this.$props.item.lists, sortRules[ 'lists' ], true )

                let lists       = this.$props.item.lists,
                    lastShort   = false,
                    firstOpened = false

                for( let l in lists )
                {
                    if( firstOpened === false )
                    {
                        if( undefined !== this.openMonths
                            && -1 === this.openMonths.indexOf( lists[ l ]._tcShortSortable ) )
                        {
                            this.openMonths.push( lists[ l ]._tcShortSortable )
                        }
                        firstOpened = lists[ l ]._tcShortSortable
                    }
                    if( lists[ l ]._tcShortSortable !== lastShort )
                    {
                        lists[ l ]._showSeparator = true
                        lastShort = lists[ l ]._tcShortSortable
                    }
                    else
                    {
                        lists[ l ]._showSeparator = false
                    }
                }

                this.viewItem = {
                    lists: lists
                }

                this.prepared = true
                this.ready = true

                return resolve()

            } )

        },

        handleOpenMonth( which )
        {
            let state = -1 < this.openMonths.indexOf( which )
            if( state === false )
            {
                if( -1 === this.openMonths.indexOf( which ) )
                {
                    this.openMonths.push( which )
                }
            }
            else
            {
                let idx = this.openMonths.indexOf( which )
                if( -1 < idx )
                {
                    this.openMonths.splice( idx, 1 )
                }
            }
        },

        headlineSetup( id, type, colType )
        {
            if( 'threewaytoggle' === colType )
            {
                return 'distinct'
            }
            let colSet = this.$core.getState( 'column-header-setup-' + this.$props.item.localId ) || {}
            return ( undefined !== colSet[ id ] ? colSet[ id ] === type : this.$props.item._tc.summary.headTypes[ id ] === type )
        },

        headlineSetupType( id )
        {
            let colSet = this.$core.getState( 'column-header-setup-' + this.$props.item.localId ) || {}
            return ( undefined !== colSet[ id ] ? colSet[ id ] : this.$props.item._tc.summary.headTypes[ id ] )
        },

        handleSwitchSetup( id, type )
        {

            let colSet = this.$core.getState( 'column-header-setup-' + this.$props.item.localId ) || {}

            colSet[ id ] = type
            this.$core.setState( 'column-header-setup-' + this.$props.item.localId, colSet )

        },

        handleRowClick( item )
        {
            this.$emit( 'rowClick', item )
        },

        toggleShowAll()
        {
            this.showAll = !this.showAll
        }
    }

}
</script>