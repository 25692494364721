<template>
    <div>
        <div class="select-wrapper" @prefillAdder="prefillAdder" v-if="initialized">
            <select :disabled="disabled"
                    class="select_element" :id="'form-element-'+refName" @click="$core.f().skip"
                    @change="checkSelect">
                <option value="">bitte wählen...</option>
                <template v-if="showTeams !== true">
                    <option v-for="colleague in colleagues" :value="colleague[idType]"
                            :selected="undefined !== value ? ( asPlainList ? value == colleague[idType] : value.value == colleague[idType] ) : false"
                            :key="'key_collsel_'+colleague[idType]">{{ colleague.lastname }}, {{ colleague.firstname }}
                    </option>
                </template>
                <template v-else>
                    <optgroup label="Teams">
                        <option v-for="team in teams" :value="'team:'+team.localId"
                                :selected="undefined !== value ? ( asPlainList ? value == 'team:'+team.localId : value.value == 'team:'+team.localId ) : false"
                                :key="'key_collsel_'+team.localId">{{ team.teamname }}
                        </option>
                    </optgroup>
                    <optgroup label="einzelne Kolleg:innen">
                        <option v-for="colleague in colleagues" :value="colleague[idType]"
                                :selected="undefined !== value ? ( asPlainList ? value == colleague[idType] : value.value == colleague[idType] ) : false"
                                :key="'key_collsel_'+colleague[idType]">{{ colleague.lastname }}, {{
                                colleague.firstname
                            }}
                        </option>
                    </optgroup>
                </template>
            </select>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ColleagueSelector',

    props: {
        value      : { Type: String, required: false },
        refName    : { Type: String, required: true },
        id         : { Type: String, required: false },
        idType     : { Type: String, required: false, default: 'localId' },
        validator  : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        showTeams  : { Type: Boolean, required: false },
        display    : { Type: String, required: false },
        disabled   : { Type: Boolean, required: false, default: false },
        editor     : { Type: String, required: false },
        flags      : { Type: String, required: false },
        allValues  : { Type: Object, required: false },
        unique     : { Type: Boolean, required: false },
        prefetch   : { Type: String, required: false },
        editItem   : { Type: Object, required: false },
        asPlainList: { Type: Boolean, required: false, default: false }
    },

    emits: [ 'change', 'update' ],

    created()
    {
        //this.select()
    },

    mounted()
    {
        this.initialize()
            .then( () =>
            {

                this.formElm = document.querySelector( '#form-element-' + this.$props.refName )
                if( this.$props.unique === true )
                {
                    this.filterUnique()
                }
                this.select()

            } )
    },

    data()
    {
        return {
            colleagues : false,
            teams      : false,
            data       : {},
            selected   : false,
            initialized: false,
            formElm    : false
        }
    },

    watch: {
        prefetch : {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.initialize()
                    this.select()
                }
            },
            deep: true
        },
        value    : {
            handler()
            {
                this.initialize()
            }
        },
        allValues: {
            deep: true,
            handler()
            {
                if( this.$props.unique === true )
                {
                    this.filterUnique()
                }
            }
        }
    },

    methods: {

        initialize()
        {

            return new Promise( resolve =>
            {

                let ccData = this.$core.cc().getAll(),
                    rules  = this.$core.settings().getSetting( 'sortingDirections' ),
                    data   = []

                for( let c in ccData )
                {
                    if( !ccData[ c ].hidden )
                    {
                        data.push( ccData[ c ] )
                    }
                }

                this.$core.getSorter().multiSortObjects( data, rules[ 'students' ] )

                let teams = this.$core
                                .getBaseClassHelper()
                                .get( 'team' )
                                .getCache( 'cache' ),
                    list  = []

                /*eslint-disable-next-line*/
                for( const [ localId, team ] of teams )
                {
                    list.push( team )
                }

                this.$core.getSorter().multiSortObjects( list, rules[ 'teams' ] )

                this.data = {}
                this.teams = list
                this.data.all = data
                this.colleagues = data

                if( undefined !== data )
                {
                    this.filterUnique()
                }

                this.initialized = true
                return resolve()

            } )

        },

        select()
        {
            if( null !== this.formElm )
            {
                if( undefined !== this.$props.value
                    && null !== this.$props.value )
                {
                    if( this.$core.f().isObject( this.$props.value )
                        && this.$core.f().isset( this.$props.value.value ) )
                    {
                        this.formElm.value = this.$props.value.value
                    }
                    else
                    {
                        this.formElm.value = this.$props.value
                    }
                }
                else
                {
                    this.formElm.value = ''
                }
            }
        },

        checkSelect()
        {
            if( null !== this.formElm )
            {
                if( '' !== this.formElm.value.trim() )
                {
                    this.$emit( 'change', this.$props.id, this.formElm.value )
                    this.$emit( 'update', this.$props.refName, 'colleagueSelector', 'update', undefined, this.formElm.value )
                }
                else
                {
                    this.$emit( 'change', this.$props.id, undefined )
                    this.$emit( 'update', this.$props.refName, 'colleagueSelector', 'update', undefined, undefined )
                }
            }
        },

        prefillAdder( event )
        {

            let temp   = event.detail.elmId.split( /_/g ),
                id     = temp.pop(),
                lookup = '_' + id

            if( -1 < this.$props.refName.indexOf( lookup ) )
            {
                if( null !== this.formElm )
                {
                    this.formElm.value = event.detail.values
                    this.checkSelect()
                }
            }

        },

        filterUnique()
        {

            this.$nextTick()
                .then( () =>
                {

                    let excludes = []

                    for( let a in this.$props.allValues )
                    {
                        excludes.push( this.$props.allValues[ a ].value.value )
                    }

                    let newList = []

                    for( let s in this.colleagues )
                    {
                        let colleague = this.colleagues[ s ]
                        if( -1 === excludes.indexOf( colleague[ this.$props.idType ] ) || colleague[ this.$props.idType ] === this.$props.value.value )
                        {

                            newList.push( colleague )
                        }
                    }

                    this.colleagues = newList

                } )

        }

    }

}
</script>