import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class SharesObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !SharesObjectClass.instance )
        {

            let refList = [
                'idReference',
                'objectType',
                'sharedWith',
                'optionals',
                'timestamp',
                'update'
            ]

            let personalAttributesStatesList = []

            super( core, 'share', refList, personalAttributesStatesList )

            this.objectType = 'shares'
            this.eventManager = core.getEventManager()

            SharesObjectClass.instance = this

        }

        return SharesObjectClass.instance

    }

    fillCache()
    {

        return new Promise( resolve =>
        {

            super.fillCache()
                 .then( () =>
                 {

                     let scopes = [ 'cache', 'archive' ]
                     for( let s in scopes )
                     {
                         for( const [ localId, share ] of this.registry[ scopes[ s ] ] )
                         {

                             this.registry.byReference.set( share.idReference, localId )

                         }
                     }

                     this.eventManager
                         .dispatchIndexed( 'on-share-refresh-component' )

                     return resolve()

                 } )

        } )

    }

    _refreshCache( localId, element )
    {

        if( undefined === element )
        {
            this.loadItem( localId )
                .then( element =>
                {

                    this.registry.byReference.set( element.idReference, localId )
                    this._refreshCache( localId, element )

                } )
                .catch( () =>
                {
                    return
                } )
        }
        else
        {
            this._setElementStates( element )
            this._handleAfterUpdate( element )
                .finally( () =>
                {
                    this._prepareIndexMaps( element )
                    this.generateSortMap()
                } )
        }

    }

    refreshCache( localId, element )
    {
        this._refreshCache( localId, element )
    }

    listAll( attach, filter, skipArchived, skipWait )
    {
        return new Promise( ( resolve, reject ) =>
        {
            super.listAll( attach, filter, skipArchived, skipWait )
                 .then( list =>
                 {

                     for( let l in list )
                     {
                         this.registry.byReference.set( list[ l ].idReference, list[ l ].localId )
                     }

                     this.eventManager
                         .dispatchIndexed( 'on-share-refresh-component' )

                     return resolve( list )

                 } )
                 .catch( e =>
                 {
                     return reject( e )
                 } )
        } )
    }

    create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
    {

        let localId = super.create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )

        this.eventManager.append( 'storable-after-update-' + localId, () =>
        {
            this.registry.byReference.set( values.idReference, localId )
            this.eventManager
                .dispatchIndexed( 'on-share-refresh-component' )
        } )

        return localId

    }

    update( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )
    {

        this.eventManager.append( 'after-updated-' + localId, () =>
        {
            this.eventManager
                .dispatchIndexed( 'on-share-refresh-component' )
        } )
        return super.update( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )

    }

    delete( localId, remoteId, forceRemote )
    {

        return new Promise( resolve =>
        {

            super.delete( localId, remoteId, forceRemote )
                 .then( () =>
                 {

                     for( const [ idReference, idLocal ] of this.registry.byReference )
                     {
                         if( idLocal === localId )
                         {
                             this.registry.byReference.delete( idReference )
                             break
                         }
                     }

                     this.eventManager
                         .dispatchIndexed( 'on-share-refresh-component' )

                     return resolve()

                 } )

        } )

    }

}