export default class CompetenceCategoryTree
{

    /*eslint-disable*/

    constructor( core )
    {

        if( !CompetenceCategoryTree.instance )
        {

            this.logger = core.getLogger()
            this.config = core.getConfig()
            this.f = core.f()
            this.eventManager = core.getEventManager()
            this.baseClassHelper = core.getBaseClassHelper()
            this.sanitizer = core.getSanitizers()

            this.initialized = false
            this.logSign = 'Core::Helpers::CompetenceCategoryTree (CCT)'

            this.reset()

            this.crIndexCompetence = this.eventManager.addIndexed( 'on-storable-create-competence', () =>
            {
                this.afterUpdate()
            } )
            this.upIndexCompetence = this.eventManager.addIndexed( 'on-storable-update-competence', () =>
            {
                this.afterUpdate()
            } )
            this.crIndexCategory = this.eventManager.addIndexed( 'on-storable-create-competenceCategory', () =>
            {
                this.afterUpdate()
            } )
            this.upIndexCategory = this.eventManager.addIndexed( 'on-storable-update-competenceCategory', () =>
            {
                this.afterUpdate()
            } )
            this.eventManager.append( 'on-list-update-containing-competenceSelector', () =>
            {
                this.afterUpdate()
            } )

            if( !this.baseClassHelper )
            {
                this.eventManager.append( 'on-baseclasses-available', ( baseClassHelper ) =>
                {

                    this.baseClassHelper = baseClassHelper
                    this.prepare()

                } )
            }
            else
            {
                this.prepare()
            }

            CompetenceCategoryTree.instance = this

        }

        return CompetenceCategoryTree.instance

    }

    reset()
    {
        this.initialized = false
        this.allSelected = {}
        this.tree = {
            categories  : {},
            competences : {},
            byRoot      : {},
            root        : {},
            referenceMap: new Map()
        }
    }

    afterUpdate()
    {
        this.reset()
        this.prepare()
        this.eventManager.dispatch( 'on-category-tree-updated' )
    }

    awaitReadiness( retry )
    {
        return new Promise( resolve =>
        {

            retry = retry || 0

            if( 10 === retry )
            {
                this.reset()
                this.prepare()
            }

            if( this.initialized )
            {
                return resolve()
            }
            else
            {
                setTimeout( () =>
                {
                    retry++
                    return resolve( this.awaitReadiness( retry ) )
                }, 1000 )
            }

        } )
    }

    parseReference( reference )
    {

        if( undefined !== reference )
        {
            let temp = reference.split( /:/g )

            if( 0 < temp.length
                && undefined !== temp[ 0 ]
                && 'undefined' !== temp[ 0 ] )
            {

                if( 2 === temp.length )
                {
                    let type    = temp[ 0 ],
                        localId = temp[ 1 ]

                    return {
                        type            : type,
                        referenceLocalId: localId
                    }
                }
                else
                {
                    return {
                        type            : 'flat',
                        referenceLocalId: temp[ 0 ]
                    }
                }
            }
        }

        return null

    }

    parseCompetenceSubs( subs )
    {

        let parsed = []

        for( let s in subs )
        {
            if( undefined !== subs[ s ].value.localId )
            {

                parsed.push( {
                    type   : subs[ s ].value.type || 'partialCompetenceField',
                    label  : subs[ s ].value.caption,
                    localId: subs[ s ].value.localId
                } )

            }
        }

        return parsed

    }

    parseSubCompetences( subs )
    {

        let parsed = []

        for( let s in subs )
        {
            if( undefined !== subs[ s ].value.localId )
            {

                parsed.push( {
                    type       : subs[ s ].value.type,
                    label      : subs[ s ].value.caption,
                    description: subs[ s ].value.description,
                    localId    : subs[ s ].value.localId
                } )

            }
        }

        return parsed

    }

    prepare( timeout )
    {

        this.logger.clog( this.logSign, 'preparing competences category tree...' )

        let scopes = [ 'cache', 'archive' ]

        setTimeout( () =>
        {

            this.baseClassHelper
                .get( 'competence' )
                .getPreparedCache()
                .then( allCompetences =>
                {

                    for( let s in scopes )
                    {

                        let scope = scopes[ s ]

                        for( const [ localId, competence ] of allCompetences[ scope ] )
                        {

                            let comp = {
                                type          : 'competence',
                                localId       : localId,
                                color         : competence.color,
                                description   : competence.description,
                                label         : competence.title,
                                reference     : this.parseReference( competence.idCompetenceCategory ),
                                subs          : this.parseCompetenceSubs( competence.subs ),
                                subCompetences: []
                            }

                            this.tree.competences[ comp.localId ] = comp

                        }

                    }

                    this.baseClassHelper
                        .get( 'competenceCategory' )
                        .getPreparedCache()
                        .then( allCategories =>
                        {

                            for( let s in scopes )
                            {

                                let scope = scopes[ s ]

                                for( const [ localId, category ] of allCategories[ scope ] )
                                {

                                    let comp = {
                                        type          : 'category',
                                        localId       : localId,
                                        color         : category.color,
                                        description   : category.description,
                                        label         : category.title,
                                        reference     : {
                                            type   : 'root',
                                            localId: localId
                                        },
                                        subCompetences: this.parseSubCompetences( category.subCompetences )
                                    }

                                    this.tree.categories[ comp.localId ] = comp

                                }

                            }

                            this.prepareTrees()

                        } )

                } )

        }, timeout || this.config.globalCacheHeaterTimeout )

    }

    parseSubs( root )
    {
        let subs = []

        for( let cc in this.tree.competences )
        {
            if( this.tree.competences[ cc ].reference
                && this.tree.competences[ cc ].reference.type === 'flat'
                && this.tree.competences[ cc ].reference.referenceLocalId === root.localId )
            {
                subs.push( this.tree.competences[ cc ] )
            }
        }

        return subs
    }

    parseSubCompetencesSubs( subCompetences )
    {

        let parsedSubs = []

        for( let s in subCompetences )
        {

            let branch = JSON.parse( JSON.stringify( subCompetences[ s ] ) )
            branch.subs = []
            branch.hasSubs = false

            for( let c in this.tree.competences )
            {
                if( this.tree.competences[ c ].reference
                    && this.tree.competences[ c ].reference.type === 'subCompetence'
                    && this.tree.competences[ c ].reference.referenceLocalId === branch.localId )
                {
                    branch.subs.push( this.tree.competences[ c ] )
                }
            }

            branch.hasSubs = 0 < branch.subs.length
            parsedSubs.push( branch )

        }

        return parsedSubs

    }

    prepareTrees()
    {

        for( let c in this.tree.categories )
        {

            let subs = this.parseSubs( this.tree.categories[ c ] )
            this.tree.categories[ c ].subs = []

            if( 0 < subs.length )
            {
                this.tree.categories[ c ].subs = subs
                this.tree.categories[ c ].hasSubs = true
            }

            if( Array.isArray( this.tree.categories[ c ].subCompetences )
                && 0 < this.tree.categories[ c ].subCompetences.length )
            {
                let subs = this.parseSubCompetencesSubs( this.tree.categories[ c ].subCompetences )
                if( 0 < subs.length )
                {
                    this.tree.categories[ c ].subs = [ ...this.tree.categories[ c ].subs, ...subs ]
                    this.tree.categories[ c ].hasSubs = true
                }
            }

            this.tree.byRoot[ c ] = this.tree.categories[ c ]

        }

        this.tree.root = JSON.parse( JSON.stringify( this.tree.byRoot ) )

        for( let t in this.tree.byRoot )
        {
            if( this.tree.byRoot[ t ].hasSubs )
            {
                let breadcrumb = this.tree.byRoot[ t ].label
                for( let s in this.tree.byRoot[ t ].subs )
                {
                    this.tree.byRoot[ t ].subs[ s ].breadcrumb = breadcrumb
                    this.tree.byRoot[ this.tree.byRoot[ t ].subs[ s ].localId ] = this.tree.byRoot[ t ].subs[ s ]
                    let subBreadcrumb = this.tree.byRoot[ t ].subs[ s ].label
                    for( let ss in this.tree.byRoot[ t ].subs[ s ].subs )
                    {
                        this.tree.byRoot[ t ].subs[ s ].subs[ ss ].breadcrumb = breadcrumb + ' > ' + subBreadcrumb
                        this.tree.byRoot[ this.tree.byRoot[ t ].subs[ s ].subs[ ss ].localId ] = this.tree.byRoot[ t ].subs[ s ].subs[ ss ]
                        let subsubBreadcrumb = this.tree.byRoot[ t ].subs[ s ].subs[ ss ].label

                        for( let sss in this.tree.byRoot[ t ].subs[ s ].subs[ ss ].subs )
                        {
                            this.tree.byRoot[ t ].subs[ s ].subs[ ss ].subs[ sss ].breadcrumb = subBreadcrumb + ' > ' + subsubBreadcrumb
                            this.tree.byRoot[ this.tree.byRoot[ t ].subs[ s ].subs[ ss ].subs[ sss ].localId ] = this.tree.byRoot[ t ].subs[ s ].subs[ ss ].subs[ sss ]
                        }
                    }
                }
            }
        }

        this.eventManager.dispatch( 'on-competence-category-tree-ready', this )

        this.initialized = true

    }

    hasSubs( element )
    {
        return Array.isArray( element.subs ) && 0 < element.subs.length
    }

    appendOnce( id, arr )
    {
        if( -1 === arr.indexOf( id ) )
        {
            arr.push( id )
        }
    }

    _toObjectList( list )
    {
        let objectList = []
        for( let l in list )
        {
            let element = this.baseClassHelper.getObjectById( list[ l ] )
            if( undefined !== element
                && !objectList.find( o => o.localId === list[ l ] ) )
            {
                objectList.push( element )
            }
        }
        return objectList
    }

    getAllLinkedCompetencesById( id )
    {

        let result    = [],
            foundRoot = undefined

        for( let t in this.tree.byRoot )
        {

            if( 'category' === this.tree.byRoot[ t ].type )
            {

                if( this.tree.byRoot[ t ].localId === id )
                {
                    foundRoot = t
                }
                else
                {
                    if( this.hasSubs( this.tree.byRoot[ t ] ) )
                    {
                        for( let s in this.tree.byRoot[ t ].subs )
                        {
                            if( id === this.tree.byRoot[ t ].subs[ s ].localId )
                            {
                                foundRoot = t
                            }
                            if( this.hasSubs( this.tree.byRoot[ t ].subs[ s ] ) )
                            {
                                for( let ss in this.tree.byRoot[ t ].subs[ s ].subs )
                                {
                                    if( id === this.tree.byRoot[ t ].subs[ s ].subs[ ss ].localId )
                                    {
                                        foundRoot = t
                                    }
                                    if( this.hasSubs( this.tree.byRoot[ t ].subs[ s ].subs[ ss ] ) )
                                    {
                                        for( let sss in this.tree.byRoot[ t ].subs[ s ].subs[ ss ].subs )
                                        {
                                            if( id === this.tree.byRoot[ t ].subs[ s ].subs[ ss ].subs[ sss ].localId )
                                            {
                                                foundRoot = t
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if( foundRoot )
        {

            result.push( foundRoot )
            if( this.hasSubs( this.tree.byRoot[ foundRoot ] ) )
            {
                for( let s in this.tree.byRoot[ foundRoot ].subs )
                {
                    this.appendOnce( this.tree.byRoot[ foundRoot ].subs[ s ].localId, result )
                    if( this.hasSubs( this.tree.byRoot[ foundRoot ].subs[ s ] ) )
                    {
                        for( let ss in this.tree.byRoot[ foundRoot ].subs[ s ].subs )
                        {
                            this.appendOnce( this.tree.byRoot[ foundRoot ].subs[ s ].subs[ ss ].localId, result )
                            if( this.hasSubs( this.tree.byRoot[ foundRoot ].subs[ s ].subs[ ss ] ) )
                            {
                                for( let sss in this.tree.byRoot[ foundRoot ].subs[ s ].subs[ ss ].subs )
                                {
                                    this.appendOnce( this.tree.byRoot[ foundRoot ].subs[ s ].subs[ ss ].subs[ sss ].localId, result )
                                }
                            }
                        }
                    }
                }
            }

        }

        return this._toObjectList( result )

    }

    pushKey( keys, localId )
    {
        if( undefined !== localId )
        {
            keys.push( this.sanitizer.cleanId( 'competence:' + localId ) )
            keys.push( 'competence:' + localId )
        }
    }

    getListFieldKeys( localId )
    {

        let keys = []
        this.pushKey( keys, localId )

        if( Array.isArray( this.tree.byRoot[ localId ].subs ) && 0 < this.tree.byRoot[ localId ].subs.length )
        {
            for( let s in this.tree.byRoot[ localId ].subs )
            {

                this.pushKey( keys, this.tree.byRoot[ localId ].subs[ s ].localId )
                if( Array.isArray( this.tree.byRoot[ localId ].subs[ s ].subs ) && 0 < this.tree.byRoot[ localId ].subs[ s ].subs.length )
                {
                    for( let ss in this.tree.byRoot[ localId ].subs[ s ].subs )
                    {
                        this.pushKey( keys, this.tree.byRoot[ localId ].subs[ s ].subs[ ss ].localId )
                    }
                }

            }
        }

        return keys

    }

}