<template>
    <div :id="'listBoundaries-'+ownId"
         :key="'listBoundaries-'+ownId">
        <template v-if="undefined !== item && prepared">
            <div :class="'list '
                    +( mode !== 'vertical' ? 'hoverable ' : '' )
                    +item.color+( selected ? ' selected-item' : '' )">
                <div class="listname" @click="handleOpen">
                    <div v-if="'combilist' === item.listType" class="combilist-icon"></div>
                    <ListDoneMark :doneInfo="doneInfo"/>
                    {{
                        item.listname
                    }}
                    <ListFunctions v-if="!clickDisabled"
                                   :open
                                   :item
                                   :mode
                                   :elementKey="functionsKey"
                                   :singleItems
                                   :filter="filter || undefined"
                                   @excel="handleExcel"
                                   @print="handlePrint"
                                   @openElement="handleOpenElement"
                                   @createElement="handleCreateElement"
                                   @showForToday="handleShowForToday()"
                                   @cloneForToday="handleClone( true )"
                                   @showScores="handleScores"
                                   @showDetails="handleDetails()"
                                   @showVertical="handleVertical()"
                                   @toggleopen="handleOpen"
                                   @toggleListFilter="showListFilters = !showListFilters"/>
                </div>
                <ListFilter v-if="showListFilters"
                            @filter="handleListFilter"/>
                <ListInfo :item="item"
                          :singleItems
                          :doneInfo
                          @open="handleOpen"
                          @detailView="handleDetailView"/>
                <div v-if="open" class="list-summary-wrapper"
                     @scroll="$emit( 'scrolling' )">
                    <ListSummary v-if="mode === 'summary'"
                                 :item="item"
                                 :elementKey="item.elementKey"
                                 :listFilter
                                 :inListEditor
                                 :openingElement
                                 @dataUpdate="handleDataUpdate()"
                                 @rowClick="handleOpenElement"/>
                    <ListScores v-if="mode === 'scores'"
                                :item="item"
                                :elementKey="item.elementKey"
                                :listFilter
                                :inListEditor
                                @dataUpdate="handleDataUpdate()"
                                @rowClick="handleOpenElement"/>
                    <ListDetails v-if="mode === 'details'"
                                 :item="item"
                                 :elementKey="item.elementKey"
                                 :listFilter
                                 :inListEditor
                                 @dataUpdate="handleDataUpdate()"
                                 @rowClick="handleOpenElement"/>
                    <ListVertical v-if="mode === 'vertical'"
                                  :item="item"
                                  :elementKey="item.elementKey"
                                  :listFilter
                                  :inListEditor
                                  @dataUpdate="handleDataUpdate()"
                                  @rowClick="handleOpenElement"/>
                    <ScoreDistribution v-if="( 'test' === item.listType || hasScoreBox === true )
                                              && 1 !== $store.getters.isStudent"
                                       :lists="isSingleItem ? [ item ] : item.lists"
                                       :elementKey="item.elementKey"/>
                </div>
            </div>
        </template>
        <template v-else>
            <div
                :class="'list synchronizing '+( mode !== 'vertical' ? 'hoverable ' : '' )+item.color">
                <ListSynchronizingPlaceholder :list="item"/>
            </div>
        </template>
    </div>
</template>

<script>
/*eslint-disable*/
import ListFunctions                from '@/components/elements/defaults/ListFunctions'
import ListSummary                  from '@/components/elements/viewItems/listViews/ListSummary'
import ViewItemHandler              from '@/components/elements/viewItems/viewHandler/ViewItemHandler'
import ScoreDistribution            from '@/components/form/elements/tests/ScoreDistribution'
import ListScores                   from '@/components/elements/viewItems/listViews/ListScores'
import ListDetails                  from '@/components/elements/viewItems/listViews/ListDetails'
import ListVertical                 from '@/components/elements/viewItems/listViews/ListVertical'
import MixinEvents                  from '@/mixins/MixinEvents'
import ListFilter                   from "@/components/elements/filters/ListFilter";
import ListDoneMark                 from "@/components/elements/viewItems/listViews/parts/ListDoneMark";
import ListInfo                     from "@/components/elements/viewItems/listViews/parts/ListInfo";
import MixinCachePreheater          from "@/mixins/MixinCachePreheater";
import ListSynchronizingPlaceholder from "@/components/elements/viewItems/listViews/ListSynchronizingPlaceHolder";
import MixinComponentCleanup        from "@/mixins/MixinComponentCleanup.vue";

export default {
    name: 'List',

    components: {
        ListSynchronizingPlaceholder,
        ListInfo,
        ListDoneMark,
        ListFilter,
        ListVertical,
        ListDetails,
        ListScores,
        ScoreDistribution,
        ListSummary,
        ListFunctions
    },

    mixins: [ MixinEvents, MixinCachePreheater, MixinComponentCleanup ],
    emits : [ 'refresh', 'detailView', 'click', 'clicked', 'open', 'scrolling', 'dataUpdate', 'prepared', 'unswipe', 'findTab' ],

    extends: ViewItemHandler,

    props: {
        item         : { type: Object, required: true },
        clickDisabled: { type: Boolean, required: false },
        elementKey   : { Type: String, required: false },
        singleItems  : { Type: Boolean, required: false },
        swiped       : { Type: Boolean, required: false },
        remoteOpen   : { type: String, required: false },
        escapeNested : { Type: Boolean, required: false, default: false },
        startOpen    : { Type: Boolean, required: false, default: false },
        selected     : { type: Boolean, required: false, default: false },
        selectMode   : { type: Boolean, required: false, default: false },
        inListEditor : { type: Boolean, required: false, default: false }
    },

    data()
    {
        return {
            ownId          : this.$core.getUuid().generate(),
            neededCaches   : [ 'student' ],
            open           : false,
            mode           : 'summary',
            editItem       : null,
            lastEdited     : false,
            recoveryTimer  : null,
            isFiltered     : false,
            filterCount    : 0,
            listFilter     : false,
            updateTimer    : null,
            changed        : false,
            doneInfo       : false,
            triggerOpen    : false,
            hasScoreBox    : false,
            valueHistory   : false,
            changeTimer    : false,
            filterBy       : false,
            listSingleItem : false,
            prepared       : false,
            showListFilters: false,
            initialLookup  : true,
            triggerIds     : [],
            openingElement : false,
            functionsKey   : false,
            emitDataUpdate : false,
            lastContentKey : false,
            timeout        : false,
            filter         : false
        }
    },

    watch: {
        'item.elementKey': {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== newValue
                    && undefined !== oldValue
                    && newValue !== oldValue )
                {
                    clearTimeout( this.timeout )
                    this.timeout = setTimeout( () =>
                    {
                        this.handleElementKeyChange()
                    }, 300 )
                }
            }
        },
        editItem         : {
            immediate: true,
            handler( item )
            {
                if( undefined !== item
                    && null !== item
                    && false !== item )
                {
                    this.launchEditor()
                }
            }
        }
    },

    computed: {
        isSingleItem()
        {
            return this.$props.singleItems || !Array.isArray( this.$props.item.lists )
        }
    },

    beforeUnmount()
    {
        clearTimeout( this.timeout )
    },

    created()
    {

        this.lastContentKey = this.$props.item.contentKey

        this.addEvent( 'on-recollect-' + this.$props.item.localId, () =>
        {
            this.$emit( 'dataUpdate' )
        } )

        try
        {
            let temp = this.$core.getState( 'detailViewFor' ).split( /:/g )
            this.filter = {
                type   : temp[ 0 ],
                localId: temp[ 1 ]
            }
        }
        catch( e )
        {
            this.filter = false
        }

        this.awaitNeededCaches()
            .then( () =>
            {

                this.functionsKey = Array.isArray( this.$props.item.lists ) && 0 < this.$props.item.lists
                                    ? this.$props.item.lists[ 0 ].elementKey : this.$props.item.elementKey
                if( this.isSingleItem )
                {
                    this.prepareListSingleItem()
                        .then( () =>
                        {
                            this.prepared = true
                            this.open = true
                            this.$emit( 'prepared' )
                        } )
                }
                else
                {
                    this.prepared = true
                    this.$emit( 'prepared' )
                }

            } )

    },

    mounted()
    {

        let tmp = this.$core.getState( 'detailViewFor' )
        if( undefined !== tmp )
        {
            let temp = tmp.split( ':' )
            this.filterBy = temp[ 0 ]
        }

        if( 1 === this.$core.getStore().getters.isStudent )
        {
            this.mode = 'test' === this.$props.item.listType ? 'scores' : 'details'
        }
        else
        {
            this.mode = 'test' === this.$props.item.listType ? 'scores' : 'summary'
            if( this.filter && 'student' === this.filter.type  && 'test' !== this.$props.item.listType )
            {
                this.mode = 'details'
            }
        }

        this.open = this.$core.getState( 'list-open-' + this.$props.item.referenceKey ) || false
        this.checkScoreBox()
        this.checkListInfo()

    },

    methods: {

        handleContentKeyChange()
        {

            if( this.lastContentKey !== this.$props.item.contentKey )
            {
                this.lastContentKey = this.$props.item.contentKey
                this.$emit( 'dataUpdate' )
            }

            this.checkListInfo()
            this.checkScoreBox()

        },

        handleElementKeyChange()
        {

            if( undefined === this.$props.item )
            {
                return
            }

            if( this.lastContentKey !== this.$props.item.contentKey )
            {
                this.lastContentKey = this.$props.item.contentKey
                this.$emit( 'dataUpdate' )
            }

            this.checkListInfo()
            this.checkScoreBox()

            if( undefined !== this.$props.item.lists[ this.$props.item.lists.length - 1 ] )
            {
                this.functionsKey = this.$props.item.lists[ this.$props.item.lists.length - 1 ].elementKey
            }

            if( true !== this.$core.settings().getSetting( 'openAfterCreate' ) )
            {
                return
            }

            let lastTrigger = this.$core.getState( 'last-trigger-open' )
            if( null !== lastTrigger )
            {
                return
            }

        },

        prepareListSingleItem()
        {

            return new Promise( resolve =>
            {

                return resolve()

            } )

        },

        checkRemoteOpener()
        {
            if( this.$core.getState( 'listEditorOpen' ) !== true
                && this.$props.item.lists )
            {

                let list = this.$props.item.lists.find( o => o.localId === this.$props.remoteOpen )
                if( undefined !== list )
                {
                    this.valueHistory = undefined !== list.values ? this.$core.f().deref( list.values ) : {}
                    this.editItem = list
                }
            }
        },

        checkScoreBox()
        {

            let listItem = this.isSingleItem ? this.$props.item : this.$props.item.lists[ 0 ]
            this.hasScoreBox = false

            if( undefined !== listItem
                && undefined !== listItem.columns )
            {
                for( let c in listItem.columns )
                {
                    let col = listItem.columns[ c ]
                    if( undefined !== col.type
                        && col.type.substr( 0, 8 ) === 'scoreBox' )
                    {
                        this.hasScoreBox = true
                        break
                    }
                }
            }

            listItem = undefined

        },

        checkListInfo()
        {
            this.$nextTick()
                .then( () =>
                {

                    if( 'recallList' === this.$props.item.listType )
                    {
                        this.doneInfo = {
                            done   : this.$props.item.isDone,
                            duedate: this.$props.item.duedate
                        }
                    }
                } )
        },

        handleOpen( event )
        {

            if( this.$props.clickDisabled )
            {
                return
            }

            if( undefined !== event )
            {
                this.$core.f().skip( event )
            }
            if( true === this.$props.selectMode )
            {
                this.$emit( 'click' )
                this.$emit( 'clicked' )
            }
            else
            {
                this.open = !this.open
                if( !this.open )
                {
                    this.showListFilters = false
                }
                this.$core.setState( 'list-open-' + this.$props.item.referenceKey, this.open )
            }
        },

        /*eslint-disable*/

        triggerUpdate()
        {

            if( null !== this.editItem )
            {

                this.$core.getEventManager()
                    .dispatch( 'storing-' + this.editItem.localId )

                this.editItem.fieldHistory = this.$core.getFieldHistoryHelper().reduce( this.editItem.fieldHistory )

                let item = this.$core.f().deref( this.editItem )
                this.valueHistory = undefined !== item.values ? item.values : undefined

                return this.$core.getBaseClassHelper()
                           .get( 'list', item.listType )
                           .update(
                               item,
                               item.localId,
                               item.remoteId,
                               item.timestamp,
                               item.localKey
                           )

            }

        },

        launchEditor()
        {

            if( true === this.$core.getState( 'list-editor-open' ) )
            {
                return
            }

            this.$core.getEventManager()
                .add( 'on-definition-update-' + this.ownId, ( event ) =>
                {
                    this.handleDefinitionUpdate( event )
                } )

            this.$core.getEventManager()
                .add( 'on-close-editor-' + this.ownId, ( event ) =>
                {
                    this.handleClose( event )
                } )

            this.$core.getUi()
                .showOverlay( {
                    type        : 'listEditor',
                    targetId    : this.ownId,
                    editItem    : this.editItem,
                    inListEditor: this.$props.inListEditor,
                    escapeNested: this.$props.escapeNested,
                    mode        : this.mode,
                    listFilter  : this.listFilter
                } )

        },

        handleClose()
        {

            this.$core.getEventManager()
                .remove( 'on-definition-update-' + this.ownId )

            this.$core.getEventManager()
                .remove( 'on-close-editor-' + this.ownId )

            this.$core.getEventManager().dispatch( 'editor-closed' )
            this.$core.setState( 'last-trigger-open', null )
            this.$bus.resetTrigger( 'lastObjectCreated' )
            this.$bus.resetTrigger( 'lastObjectType' )

            this.valueHistory = null
            this.editItem = null

        },

        getListForToday()
        {

            if( undefined !== this.$props.item.lists )
            {
                for( let l in this.$props.item.lists )
                {
                    if( this.$core.getFriendlyTimestamp().timestampIsToday( this.$props.item.lists[ l ].timestamp ) )
                    {
                        return this.$props.item.lists[ l ]
                    }
                }
            }
            else
            {
                if( this.$core.getFriendlyTimestamp().timestampIsToday( this.$props.item ) )
                {
                    return this.$props.item
                }
            }

            return undefined

        },

        handleShowForToday()
        {

            let list = this.getListForToday()

            this.valueHistory = this.$core.f().deref(  list.values )
            this.editItem = list

        },

        handleClone( today, timestamp )
        {

            this.openList()
            this.$core.setState( 'last-action-cloned', true )
            this.$core.getUi()
                .delay( () =>
                {
                    this.$core.setState( 'last-action-cloned', false )
                }, 2000 )

            if( today )
            {
                this.$core.getBaseClassHelper().get( this.$props.item.type )
                    .clone( this.$props.item.localId, Date.now() )
            }
            else
            {
                this.$core.getBaseClassHelper().get( this.$props.item.type )
                    .clone( this.$props.item.localId, timestamp )
            }

        },

        openList()
        {
            if( !this.open )
            {
                this.open = true
            }
        },

        handleScores( which )
        {
            this.openList()
            this.mode = which //'scores' === this.mode ? 'summary' : 'scores'
        },

        handleDetails()
        {
            this.openList()
            this.mode = 'details' === this.mode ? 'summary' : 'details'
        },

        handleVertical()
        {
            this.openList()
            this.mode = 'vertical' !== this.mode ? 'vertical' : 'summary'
        },

        handleUpdate( values, valueHistory )
        {

            if( null === this.editItem )
            {
                return
            }

            if( this.editItem.listType !== 'combilist' )
            {

                this.editItem.values = values
                this.editItem.fieldHistory = this.editItem.fieldHistory || {}

                if( Array.isArray( this.editItem.fieldHistory )
                    || 'create' === this.editItem.fieldHistory )
                {
                    this.editItem.fieldHistory = {}
                }

                if( undefined !== valueHistory )
                {
                    this.editItem.fieldHistory[ valueHistory.id_author + '-' + valueHistory.timestamp ] = valueHistory
                }

                this.lastEdited = this.$core.f().deref( this.editItem )
                this.changed = true

                this.$core.getCoreTimer()
                    .removeTimeout( 'list-updatetimer' )

                this.$core.getCoreTimer()
                    .addTimeout( 'list-updatetimer', 2000, () =>
                    {
                        this.triggerUpdate()
                    } )

            }

        },

        handleDefinitionUpdate( listObject )
        {

            this.valueHistory = undefined !== listObject.values ? this.$core.f().deref( listObject.values ) : {}
            this.editItem = listObject
            this.changed = true
            this.handleClose()

        },

        handleOpenElement( element )
        {

            this.openingElement = element.localId

            this.$emit( 'unswipe' )

            if( true === this.$props.selectMode
                || ( true === this.$props.inListEditor
                     && !this.$core.getListUpdateManager().hasHook( element.localId ) ) )
            {
                this.$emit( 'click', element.localId )
            }
            else if( ( true === this.$props.inListEditor
                       && this.$core.getListUpdateManager().hasHook( element.localId ) ) )
            {
                this.$emit( 'findTab', element.localId )
            }
            else
            {
                if( true !== this.$props.swiped )
                {

                    this.valueHistory = undefined !== element.values ? this.$core.f().deref( element.values ) : {}
                    this.emitDataUpdate = true
                    this.$core.setState( 'dbg-start', Date.now() )
                    this.editItem = element

                }
            }
        },

        handleCreateElement( timestamp )
        {
            this.handleClone( false, timestamp )
        },

        checkVertical()
        {
            if( this.mode === 'vertical' )
            {
                let opener = document.querySelector( '.list-detail-opener' )
                if( null !== opener )
                {
                    if( opener.classList.contains( 'list-closer' ) )
                    {
                        return false
                    }
                    else
                    {
                        return true
                    }
                }
            }

            return false

        },

        handlePrint( print )
        {

            if( !this.open )
            {
                this.handleOpen()
                this.$core.getUi().delay( () =>
                {
                    this.handlePrint( true )
                }, 300 )
            }
            else
            {
                if( !print && 'vertical' === this.mode )
                {
                    this.$core.getEventManager()
                        .dispatch( 'on-toggle-show-all' )
                    this.$core.getUi().delay( () =>
                    {
                        this.handlePrint( true )
                    }, 300 )
                }
                else
                {
                    print = true
                }
            }

            if( print === true )
            {

                let listItem    = this.isSingleItem ? this.$props.item : this.$props.item.lists[ 0 ],
                    appendTitle = undefined

                if( undefined !== listItem.columns[ 0 ].filterBy )
                {

                    let element = this.$core.getBaseClassHelper()
                                      .getObjectById( listItem.columns[ 0 ].filterBy )

                    if( 'all' === listItem.columns[ 0 ].filterBy )
                    {
                        appendTitle = ' ' + this.$core.t( 'generic-all-students' )
                    }
                    else
                    {
                        appendTitle = this.$core.t( 'object-type-' + element.type )
                        switch( element.type )
                        {
                            case 'class':
                                appendTitle += ' ' + element.classname
                                break
                            case 'group':
                                appendTitle += ' ' + element.groupname
                                break
                        }
                    }

                    this.$core.getPrinting()
                        .print( listItem, this.mode, undefined, { appendTitle: appendTitle } )

                }
                else
                {
                    this.$core.getPrinting()
                        .print( listItem, this.mode, undefined, { appendTitle: appendTitle } )
                }
            }

        },

        handleExcel()
        {
            if( !this.open )
            {
                this.handleOpen()
            }
            let listItem = this.isSingleItem ? this.$props.item : this.$props.item.lists[ 0 ]
            this.$core.getExcel()
                .export( listItem, 'printable-' + this.$props.item.referenceKey )
        },

        handleDataUpdate()
        {
            if( true === this.emitDataUpdate )
            {
                this.$emit( 'dataUpdate' )
                this.emitDataUpdate = false
            }
        },

        handleListFilter( filter, value )
        {
            this.listFilter = value
        }

    }

}
</script>