<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )" mode="in-out">
        <div v-if="undefined !== studentList"
             class="list fullscreen" :id="'list-horizontal-container-'+list.localId">

            <CollabEditingNotification :active="notificationActive" :html="notificationText"
                                       :count="notificationCount"/>

            <table :class="'list fullscreen '+list.listType" :id="'list-editor-horizontal-'+list.localId"
                   :key="localChangeKey">
                <thead :class="headClass" @click="toggleHeadDimensions">
                <tr class="list-header">
                    <template v-for="( col, index ) in list.columns"
                              :key="'th-col-'+col.columnId+'-'+index">
                        <TableHeadFixed v-if="'fixed' === col.type"
                                        :caption="col.caption"
                                        :color="list.color"
                                        :values="col.values"
                                        @show-edit-all="toggleShowEditAll"/>
                        <TableHeadRotated v-if="'fixed' !== col.type"
                                          :color="list.color"
                                          :score="col.score"
                                          :fixed="true"
                                          :key="'table-head-rotated-'+index+'-'+col.type"
                                          :keyV="index"
                                          :allKeys="list.columns.length"
                                          :caption="(col.type !== 'studentData' ? col.caption : $core.t( 'caption_'+col.caption ))"/>
                    </template>
                    <template v-if="showCalcFields">
                        <CalculationTableHeaders :list="list"
                                                 :fixed="true"
                                                 :viewKey="'cth-'+list.localId"
                                                 @count="expandHeaders = $event"/>
                    </template>
                    <th v-if="list.listType === 'test'"
                        :class="'fixed '+list.color"
                        :key="'th-col-total'">
                        <span class="rotated">Gesamt</span>
                        <br/><span class="score-heading">&sum; Punkte ({{
                            $core.getReformatter().localizedFloat( totalScore, 1 )
                        }})</span>
                    </th>
                    <th v-if="list.listType === 'test'"
                        :class="'fixed '+list.color"
                        :key="'th-col-note'">
                        <span class="rotated">Note</span>
                    </th>
                </tr>
                </thead>
                <tbody>

                <!-- OOPSIE -->
                <tr v-if="listFilterInline && 0 === listFilterMatches" class="editall">
                    <td colspan="99" style="padding: 20px 40px; text-align: center;">
                        {{ $core.t( 'list-filter-no-matches' ) }}
                    </td>
                </tr>
                <!-- // OOPSIE END -->

                <!-- EDIT ALL -->
                <tr v-if="showEditAll" class="editall">
                    <td v-for="field in lines[0]"
                        :class="( -1 < foreignHighlighted.indexOf( field.id ) ? 'foreign-highlighted ' : ' ' )
                                        +( undefined !== tdmarks[field.id] ? tdmarks[field.id] + ' ' : '' )
                                        +(false !== field.component ? 'td-'+field.component.toLowerCase() : '')
                                        +( field.disabled ? ' disabled' : '' )"
                        :id="'nonswipeable-all-'+field.id"
                        :key="'td-all-'+field.name">
                        <component v-if="field.component !== false"
                                   cssClass="list"
                                   :refName="'all-'+field.id"
                                   :id="'all-'+field.id"
                                   :tdAncestor="'nonswipeable-all-'+field.id"
                                   :placeholder="field.placeholder"
                                   :is="field.component"
                                   :referenceObjectId="list.localId"
                                   :highlighted="foreignHighlighted"
                                   :disabled="readOnly"
                                   :value="editingAllValues[ 'all-'+field.id ]"
                                   @focussed="handleFocus"
                                   @blurred="handleBlur"
                                   @update="handleAllUpdate"/>
                        <template v-else>
                            <span class="editall">alle Zeilen</span>
                        </template>
                    </td>
                    <template v-if="showCalcFields">
                        <template v-for="( col, calcIndex ) in list.calculatedColumns"
                                  :key="'calc-filler-all-'+calcIndex">
                            <td class="td-total">
                                &ndash;
                            </td>
                        </template>
                    </template>
                    <td v-if="'test' === list.listType"
                        class="td-total" :key="'td-all-total'"
                        :id="'total-all'">
                        &bull;
                    </td>
                    <td v-if="'test' === list.listType"
                        class="td-note" :key="'td-all-note'"
                        :id="'rating-all'">
                        &bull;
                    </td>
                </tr>
                <!-- // EDIT ALL -->

                <!-- LINES -->
                <template v-for="( line, index ) in lines"
                          :key="'tpl-tr-'+index">
                    <tr v-show="isVisible.get( line[0].id )"
                        :id="'tr-'+list.localId+'-'+index">
                        <td v-for="( field, fieldIndex ) in line"
                            :class="( -1 < foreignHighlighted.indexOf( field.id ) ? 'foreign-highlighted ' : ' ' )
                                        +( undefined !== tdmarks[field.id] ? tdmarks[field.id] + ' ' : '' )
                                        +(false !== field.component ? 'td-'+field.component.toLowerCase() : '')
                                        +( field.disabled ? ' disabled' : '' )"
                            :id="( false === field.component ? 'swipeable-'+index+'-'+fieldIndex : 'nonswipeable-'+index+'-'+field.id )"
                            :key="'td-'+index+'-'+field.name">
                            <template v-if="field.component !== false">
                                <CollabEditingFocusBox :colleague="focussedBy[ field.id ]"/>
                                <component cssClass="list"
                                           :refName="field.id"
                                           :id="field.id"
                                           :tdAncestor="( false === field.component ? 'swipeable-'+index+'-'+fieldIndex : 'nonswipeable-'+index+'-'+field.id )"
                                           :placeholder="field.placeholder"
                                           :is="field.component"
                                           :referenceObjectId="list.localId"
                                           :highlighted="foreignHighlighted"
                                           :value="field.display"
                                           :static="false"
                                           :disabled="readOnly"
                                           @focussed="handleFocus"
                                           @blurred="handleBlur"
                                           @update="handleFieldUpdate"/>
                            </template>
                            <template v-else>
                                <FunctionButton :item="field"
                                                v-if="index === swiped"
                                                type="delete"
                                                addClass="absolute-center"
                                                @clicked="handleRowDelete( field )"/>
                                <span v-html="field.display"></span>
                            </template>
                            <input type="hidden" :id="'row-'+list.localId+'-'+line[0].id"
                                   :value="'tr-'+list.localId+'-'+index"/>
                        </td>
                        <template v-if="showCalcFields">
                            <template v-for="( col, calcIndex ) in list.calculatedColumns"
                                      :key="'calc-result-'+calcIndex">
                                <td class="td-total">
                                    <template v-if="undefined !== calculatedRows[ $core.getSanitizers().cleanId( col.label ) ]
                                                    && undefined !== calculatedRows[ $core.getSanitizers().cleanId( col.label ) ][ line[0].id ]">
                                        {{
                                            calculatedRows[ $core.getSanitizers().cleanId( col.label ) ][ line[ 0 ].id ].display
                                        }}
                                    </template>
                                    <template v-else>
                                        &ndash;
                                    </template>
                                </td>
                            </template>
                        </template>
                        <td v-if="'test' === list.listType"
                            class="td-total" :key="'td-'+index+'-total'"
                            :id="'total-'+line[0].id">
                            &bull;
                        </td>
                        <td v-if="'test' === list.listType"
                            class="td-note" :key="'td-'+index+'-note'"
                            :id="'rating-'+line[0].id">
                            &bull;
                        </td>
                    </tr>

                    <!-- SELECTED HISTORY -->
                    <tr v-if="selectedHistory"
                        class="history-line">
                        <td v-for="( field, fieldIndex ) in line"
                            :class="historyDiffClass( field.id, field.display )"
                            :key="'td-history-'+index+'-'+field.name">
                            <template v-if="field.component !== false && hasHistory( field.id )">
                                <component cssClass="list"
                                           :refName="'hist-'+selectedHistory.id+'-'+field.id"
                                           :id="'hist-'+selectedHistory.id+'-'+field.id"
                                           :tdAncestor="'nonswipeable-'+fieldIndex+'-'+field.id"
                                           :placeholder="field.placeholder"
                                           :is="field.component"
                                           :highlighted="foreignHighlighted"
                                           :value="historicValue( field.id )"
                                           :static="false"
                                           :disabled="true"/>
                            </template>
                            <template v-else>
                                <span>&nbsp;</span>
                            </template>
                            <div class="history-overlay"
                                 @click="useHistory( field.id, historicValue( field.id ) )"></div>
                        </td>
                        <td v-if="'test' === list.listType"
                            class="td-total" :key="'td-'+index+'-total'"
                            :id="'hist-total-'+line[0].id">
                            &bull;
                        </td>
                        <td v-if="'test' === list.listType"
                            class="td-note" :key="'td-'+index+'-note'"
                            :id="'hist-rating-'+line[0].id">
                            &bull;
                        </td>
                    </tr>
                    <!-- // END SELECTED HISTORY -->

                </template>
                <!-- LINES -->
                <!--
                            <tr :id="'filler-'+list.localId">
                                <td v-for="field in lines[0]"
                                    :key="'filler-'+field.name">
                                    &nbsp;
                                </td>
                                <td v-if="list.listType === 'test'">&nbsp;</td>
                                <td v-if="list.listType === 'test'">&nbsp;</td>
                            </tr>
                -->
                </tbody>
                <tfoot>
                <td v-for="col in list.columns"
                    :id="'foot-'+$core.getSanitizers().cleanId( col.caption )"
                    :key="'foot-'+$core.getSanitizers().cleanId( col.caption )"
                    class="table-footer-space">
                </td>
                <td v-if="list.listType === 'test'"
                    class="table-footer-space">&nbsp;
                </td>
                <td v-if="list.listType === 'test'"
                    class="table-footer-space">&nbsp;
                </td>
                </tfoot>
            </table>
        </div>
    </transition>
</template>

<script>
/*eslint-disable*/
import ListFilterable          from "@/components/elements/viewItems/listViews/ListFilterable";
import MixinListEditorBase     from "@/components/elements/viewItems/listEditor/mixins/MixinListEditorBase";
import CalculationTableHeaders from "@/components/elements/viewItems/listViews/CalculationTableHeaders.vue";
import MixinComponentCleanup   from "@/mixins/MixinComponentCleanup.vue";

export default {
    name      : "ListEditorHorizontal",
    components: { CalculationTableHeaders },
    extends   : ListFilterable,
    mixins    : [ MixinListEditorBase, MixinComponentCleanup ],

    props: {
        list            : { Type: Object, required: true },
        students        : { Type: Object, required: true },
        studentList     : { Type: Array, required: true },
        reference       : { Type: Object, required: false },
        lines           : { Type: Array, required: true },
        tdmarks         : { Type: Object, required: true },
        swiped          : { Type: Boolean || Number, required: false, default: false },
        listFilterInline: { Type: String, required: false },
        editMode        : { Type: String, required: true },
        showCalcFields  : { Type: Boolean, required: false, default: false },
        readOnly        : { Type: Boolean, required: false, default: false },
        selectedHistory : { Type: Boolean || Number, required: false, default: false }
    },

    data()
    {
        return {
            totalScore: 0
        }
    },

    created()
    {
        if( undefined !== this.$props.list.columns )
        {
            for( let c in this.$props.list.columns )
            {
                this.totalScore += this.$props.list.columns[ c ].score ? parseFloat( this.$props.list.columns[ c ].score ) : 0
            }
        }
    },

    mounted()
    {
        if( undefined !== this.$props.reference )
        {

            this.$nextTick()
                .then( () =>
                {

                    this.autoScroll()

                } )

        }
    },

    methods: {

        findYPos( tr, firstTh, lastYPos, same )
        {
            return new Promise( resolve =>
            {

                let rect   = tr.getBoundingClientRect(),
                    thRect = firstTh.getBoundingClientRect()

                lastYPos = lastYPos || 0
                same = same || 0

                setTimeout( () =>
                {

                    let yPos = Math.ceil( rect.top ) - firstTh.offsetHeight - thRect.top

                    if( yPos === lastYPos )
                    {
                        same++
                    }

                    if( same < 5 )
                    {
                        return resolve( this.findYPos( tr, firstTh, yPos, same ) )
                    }
                    else
                    {
                        return resolve( yPos )
                    }

                }, 100 )

            } )

        },

        autoScroll()
        {

            try
            {


                let selector  = this.$props.reference.localId,
                    anchor    = document.querySelector( '#row-' + this.$props.list.localId + '-' + selector ),
                    container = document.querySelector( '#list-horizontal-container-' + this.$props.list.localId ),
                    firstTh   = container.querySelector( 'table > thead > tr > th' )

                if( null !== anchor )
                {

                    let jumpId = anchor.value,
                        tr     = document.querySelector( '#' + jumpId )

                    this.findYPos( tr, firstTh )
                        .then( yPos =>
                        {

                            container.scrollBy( { top: yPos, left: 0, behavior: 'smooth' } )

                        } )

                }

            }
            catch( e )
            {
                this.$core.getLogger().cerror( 'ListEditorHorizontal::mounted > autoScroll', 'failed to scroll to position: ' + e )
            }

        }

    }

}
</script>