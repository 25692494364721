export default class afterAllStudent
{

    constructor( core )
    {
        this.baseClassHelper = core.getBaseClassHelper()
    }

    _combine( student )
    {
        student.address = student.address_1 + '<br/>' + student.address_2
        student.zipcode_city = student.zipcode + '&nbsp;' + student.city
        student.parents = student.parent_1 + '<br/>' + student.parent_2
        student.phone1 = ( undefined !== student.label_phone_1 ? student.label_phone_1 + ': ' : '' ) + student.phone_1
        student.phone2 = ( undefined !== student.label_phone_2 ? student.label_phone_2 + ': ' : '' ) + student.phone_2
    }

    _fetchDependencies( skipArchived )
    {

        return new Promise( resolve =>
        {

            this.classClass = this.classClass || this.baseClassHelper.get( 'class' )
            this.groupClass = this.groupClass || this.baseClassHelper.get( 'group' )
            this.yeargroupClass = this.yeargroupClass || this.baseClassHelper.get( 'yeargroup' )

            this.classClass.getPreparedCache()
                .then( classes =>
                {

                    this.groupClass.getPreparedCache()
                        .then( groups =>
                        {

                            this.yeargroupClass.getPreparedCache()
                                .then( yeargroups =>
                                {

                                    let studentToClass     = {},
                                        studentToGroup     = {},
                                        studentToYearGroup = {},
                                        scopes             = [ 'cache' ]

                                    if( !skipArchived )
                                    {
                                        scopes.push( 'archive' )
                                    }

                                    for( let s in scopes )
                                    {
                                        for( const [ localId, classElement ] of classes[ scopes[ s ] ] )
                                        {

                                            if( !classElement.isShadowCopy )
                                            {

                                                for( let s in classElement.students )
                                                {
                                                    studentToClass[ classElement.students[ s ] ] = {
                                                        classId  : localId,
                                                        classname: classElement.classname,
                                                        color    : classElement.color
                                                    }
                                                }

                                            }

                                        }

                                        for( const [ localId, group ] of groups[ scopes[ s ] ] )
                                        {

                                            if( !group.isShadowCopy )
                                            {
                                                for( let s in group.students )
                                                {
                                                    if( undefined === studentToGroup[ group.students[ s ] ] )
                                                    {
                                                        studentToGroup[ group.students[ s ] ] = []
                                                    }
                                                    studentToGroup[ group.students[ s ] ].push( {
                                                        groupId  : localId,
                                                        groupname: group.groupname,
                                                        color    : group.color
                                                    } )
                                                }
                                            }
                                        }

                                        for( const [ localId, yeargroup ] of yeargroups[ scopes[ s ] ] )
                                        {

                                            if( !yeargroup.isShadowCopy )
                                            {
                                                for( let s in yeargroup.students )
                                                {
                                                    studentToYearGroup[ yeargroup.students[ s ] ] = {
                                                        yeargroupId  : localId,
                                                        yeargroupname: yeargroup.yeargroupname,
                                                        color        : yeargroup.color
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    return resolve( {
                                        classes           : classes,
                                        groups            : groups,
                                        yeargroups        : yeargroups,
                                        studentToClass    : studentToClass,
                                        studentToGroup    : studentToGroup,
                                        studentToYearGroup: studentToYearGroup
                                    } )

                                } )

                        } )

                } )

        } )

    }

    afterFetch( students, skipArchived )
    {
        return new Promise( resolve =>
        {

            this._fetchDependencies( skipArchived )
                .then( dependencies =>
                {

                    for( let s in students )
                    {

                        this._combine( students[ s ] )

                        students[ s ].displayName = students[ s ].lastname + ', ' + students[ s ].firstname
                        students[ s ].htmlName = '<strong>' + students[ s ].lastname + '</strong>, ' + students[ s ].firstname
                        students[ s ].inGroups = {}

                        if( undefined !== dependencies.studentToClass[ students[ s ].localId ] )
                        {

                            students[ s ].classname = dependencies.studentToClass[ students[ s ].localId ].classname
                            students[ s ].displayName += ' - Klasse ' + students[ s ].classname
                            students[ s ].classId = dependencies.studentToClass[ students[ s ].localId ].classId

                        }
                        else
                        {
                            students[ s ].classname = 'Z_FALSE'
                        }

                        if( undefined !== dependencies.studentToGroup[ students[ s ].localId ] )
                        {
                            students[ s ].inGroups = dependencies.studentToGroup[ students[ s ].localId ]
                            students[ s ].groupId = []
                            for( let g in dependencies.studentToGroup[ students[ s ].localId ] )
                            {
                                students[s].groupId.push( dependencies.studentToGroup[ students[ s ].localId ][g].groupId )
                            }
                        }

                        if( undefined !== dependencies.studentToYearGroup[ students[ s ].localId ] )
                        {
                            students[ s ].yeargroupname = dependencies.studentToYearGroup[ students[ s ].localId ].yeargroupname
                            students[ s ].displayName += ' - Jahrgang ' + students[ s ].yeargroupname
                            students[ s ].yeargroupId = dependencies.studentToYearGroup[ students[ s ].localId ].yeargroupId
                        }

                        if( true === students[ s ].archived )
                        {
                            students[ s ].displayName = 'archiviert: ' + students[ s ].displayName
                        }

                    }

                    return resolve( students )


                } )
                .catch( () =>
                {

                    return resolve( students )

                } )

        } )
    }

    afterUpdate( students, cache, skipArchived )
    {

        return new Promise( resolve =>
        {

            this.afterFetch( students, skipArchived )
                .then( result =>
                {

                    return resolve( result )

                } )

        } )

    }

}